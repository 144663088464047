import Component from '../ecs/component';
export default class MobComponent extends Component {
    constructor({ id, name }) {
        super();
        this.$id = 'mob';
        this.id = id;
        this.name = name;
    }
}
MobComponent.id = 'mob';
