/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-var-requires */
const spawnMobs = require('./zone_monster.png');
const unlockZones = require('./zone_path.png');
const death = require('./death.png');
const lifeCrystal = require('./life_crystal.png');
const ICON_ASSETS = {
    death,
    spawnMobs,
    unlockZones,
    lifeCrystal,
};
export default ICON_ASSETS;
