import { Game } from '@local/game';
import copy from 'fast-copy';
import { createPatch } from 'rfc6902';
import CharacterGateway from './controllers/characterGateway';
// This is a game server, or world instance...
export default class Room {
    // Start the game one room creation?
    constructor() {
        this.game = new Game();
        this.onPlayerState = this.onPlayerState.bind(this);
    }
    // Is this needed?
    start() {
        this.game.start();
        this.clientStates = {};
        this.characterIdToEntityId = {};
        // This is temp
        this.clientSaveCounter = {};
        // After startup hook into the event bus
        this.onRender = this.onRender.bind(this);
        this.game.eventBus.addListener('RENDER', this.onRender);
        this.game.eventBus.addListener('PLAYER_STATE', this.onPlayerState);
        // How do we handle moving between zones?
        // this.game.eventBus.fire('LOAD_ZONE', { zoneId: 'the_meadow' });
    }
    // The game room handles saving etc... and loading?
    join({ characterId }) {
        if (!this.game.isRunning) {
            this.start();
        }
        // assign state to the client...
        this.clientStates[characterId] = {};
        this.clientSaveCounter[characterId] = 0;
        let newCharacterId;
        let characterState = CharacterGateway.loadCharacter(characterId);
        if (!characterState.length) {
            // TODO: ERROR
            [
                newCharacterId,
                characterState,
            ] = CharacterGateway.createCharacter();
        }
        characterState.forEach((entity) => {
            this.game.eventBus.queue('ADD_ENTITY', entity);
        });
    }
    leave({ characterId }) {
        delete this.clientStates[characterId];
        delete this.characterIdToEntityId[characterId];
        delete this.clientSaveCounter[characterId];
        // DELETE STATE
        this.game.stop();
    }
    onRender({ characterId, entityId, state }) {
        // IF a client does not have an empty state, it cannot receive updates as it might still be joining...
        if (!this.clientStates[characterId]) {
            return;
        }
        // capture the diff
        const diff = createPatch(this.clientStates[characterId], state);
        if (!diff.length) {
            return;
        }
        this.clientStates[characterId] = copy(state);
        this.characterIdToEntityId[characterId] = entityId;
        // The server always tells the client WHO it is
        // If there was a change, fire the render method to update clients
        this.sendMessageToClient('render', {
            playerEntityId: entityId,
            patch: diff,
        });
    }
    // TEMP helper functions to simulate a websocket client...
    sendMessageToClient(eventId, eventValue) { }
    sendMessageToServer(characterId, eventId, eventValue) {
        if (!this.game.isRunning) {
            return;
        }
        this.game.eventBus.queue('PLAYER_INPUT', {
            entityId: this.characterIdToEntityId[characterId],
            eventId,
            eventValue,
        });
    }
    // temp temp
    stop() {
        this.game.stop();
    }
    // TEMP TEMP
    onPlayerState(eventValue) {
        this.clientSaveCounter[eventValue.characterId] += 1;
        if (this.clientSaveCounter[eventValue.characterId] >= 60) {
            this.clientSaveCounter[eventValue.characterId] = 0;
            CharacterGateway.saveCharacter(eventValue);
        }
    }
}
