import React, { useMemo } from 'react';
import { useGameState } from '../../../components/gameStateProvider';
import ItemClickable from '../../ui/itemClickable';
import EntityManager from '../../utils/entityManager';
import EquipmentSlot from './equipmentSlot';
const EquipmentSlots = () => {
    const gameState = useGameState();
    const { equipment } = gameState.player.components;
    const equipmentSlots = useMemo(() => {
        const temp = {};
        Object.entries(equipment.slots).forEach(([slot, slotItemId]) => {
            if (!slotItemId) {
                return;
            }
            const itemEntity = EntityManager.getEntity(gameState.world, slotItemId);
            // Check if the item is used by the slot
            const { equipable } = itemEntity.components;
            if (!equipable.slots.includes(slot)) {
                return;
            }
            temp[slot] = (React.createElement(ItemClickable, { entity: itemEntity, className: "w-full h-full" }));
        });
        return temp;
    }, [gameState.entities, equipment]);
    return (React.createElement("div", { className: "flex flex-col flex-wrap w-full space-y-2" },
        React.createElement("div", { className: "flex flex-row flex-wrap w-full" },
            React.createElement("div", { className: "flex items-end w-4/12" }),
            React.createElement("div", { className: "flex justify-center w-4/12" },
                React.createElement(EquipmentSlot, { slot: "helmet" }, equipmentSlots.helmet)),
            React.createElement("div", { className: "flex justify-start w-4/12" })),
        React.createElement("div", { className: "flex flex-row flex-wrap w-full" },
            React.createElement("div", { className: "flex justify-end w-4/12" },
                React.createElement(EquipmentSlot, { slot: "rightHand" }, equipmentSlots.rightHand)),
            React.createElement("div", { className: "flex justify-center w-4/12" },
                React.createElement(EquipmentSlot, { slot: "chest" }, equipmentSlots.chest)),
            React.createElement("div", { className: "flex justify-start w-4/12" },
                React.createElement(EquipmentSlot, { slot: "leftHand" }, equipmentSlots.leftHand))),
        React.createElement("div", { className: "flex flex-row flex-wrap w-full" },
            React.createElement("div", { className: "flex justify-end w-4/12" },
                React.createElement(EquipmentSlot, { slot: "gloves" }, equipmentSlots.gloves)),
            React.createElement("div", { className: "flex justify-center w-4/12" },
                React.createElement(EquipmentSlot, { slot: "legs" }, equipmentSlots.legs)),
            React.createElement("div", { className: "flex justify-center w-4/12" })),
        React.createElement("div", { className: "flex flex-row flex-wrap w-full" },
            React.createElement("div", { className: "flex justify-end w-4/12" }),
            React.createElement("div", { className: "flex justify-center w-4/12" },
                React.createElement(EquipmentSlot, { slot: "boots" }, equipmentSlots.boots)),
            React.createElement("div", { className: "flex justify-start w-4/12" }))));
};
export default EquipmentSlots;
