// Change ids to
// one_handed_sword_t1
// one_handed_sword_t2
// one_handed_sword_t3?
export default [
    {
        id: 'iron_sword',
        name: 'Iron Sword',
        class: 'item',
        minLevel: 1,
        tags: ['sword', 'weapon', 'default'],
        stats: [
            {
                stat: 'attackSpeed',
                min: 800,
            },
            {
                stat: 'minPhysicalDamage',
                min: 2,
                max: 2,
            },
            {
                stat: 'maxPhysicalDamage',
                min: 7,
                max: 7,
            },
        ],
        slots: ['leftHand', 'rightHand'],
        blocks: [],
    },
    {
        id: 'broad_sword',
        name: 'Broad Sword',
        class: 'item',
        minLevel: 10,
        tags: ['sword', 'weapon', 'default'],
        stats: [
            {
                stat: 'attackSpeed',
                min: 850,
            },
            {
                stat: 'minPhysicalDamage',
                min: 7,
            },
            {
                stat: 'maxPhysicalDamage',
                min: 14,
            },
        ],
        slots: ['leftHand', 'rightHand'],
        blocks: [],
    },
    {
        id: 'iron_chest',
        name: 'Iron Chest',
        class: 'item',
        minLevel: 1,
        tags: ['chest', 'armour', 'default'],
        stats: [
            {
                stat: 'armour',
                min: 8,
                max: 11,
            },
        ],
        slots: ['chest'],
        blocks: [],
    },
    {
        id: 'steel_chest',
        name: 'Steel Chest',
        class: 'item',
        minLevel: 10,
        tags: ['chest', 'armour', 'default'],
        stats: [
            {
                stat: 'armour',
                min: 45,
                max: 48,
            },
        ],
        slots: ['chest'],
        blocks: [],
    },
    {
        id: 'iron_boots',
        name: 'Iron Boots',
        class: 'item',
        minLevel: 1,
        tags: ['boots', 'armour', 'default'],
        stats: [
            {
                stat: 'armour',
                min: 2,
                max: 3,
            },
        ],
        slots: ['boots'],
        blocks: [],
    },
    {
        id: 'steel_boots',
        name: 'Steel Boots',
        class: 'item',
        minLevel: 10,
        tags: ['boots', 'armour', 'default'],
        stats: [
            {
                stat: 'armour',
                min: 8,
                max: 9,
            },
        ],
        slots: ['boots'],
        blocks: [],
    },
];
