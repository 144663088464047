import React from 'react';
import MOB_ASSETS from '../../../assets/images/mobs';
import ProgressBar from '../../../components/progressBar';
import i18n from '../../../i18n/i18n';
const Mob = ({ entity }) => {
    const { stats, mob } = entity.components;
    const currentLife = Math.round(stats.current.life);
    const { maxLife } = stats.current;
    return (React.createElement("div", { className: "flex flex-col mb-3" },
        React.createElement("div", null,
            React.createElement("div", { className: "w-full text-black dark:text-white text-sm font-medium flex justify-between" },
                React.createElement("span", null, "Life"),
                React.createElement("span", null,
                    currentLife,
                    " / ",
                    stats.current.maxLife)),
            React.createElement(ProgressBar, { className: "w-full my-0.5 bg-red-500", color: "bg-green-500", current: currentLife, max: maxLife })),
        React.createElement("div", { className: "mb-1 self-center" },
            React.createElement("img", { className: "w-auto max-h-48", src: MOB_ASSETS[mob.id], alt: i18n.get('mob', mob.id) })),
        React.createElement("div", { className: "mb-3 self-center" },
            React.createElement("div", { className: "self-center text-black dark:text-white text-lg font-medium mb-0.5" }, i18n.get('mob', mob.id)))));
};
export default Mob;
