import React from 'react';
import { render } from 'react-dom';
import GameClient from './api/gameClient';
import { GameClientProvider } from './api/gameClientProvider';
import App from './app';
// Browser specific providers...
const gameClient = new GameClient();
render(React.createElement(GameClientProvider, { gameClient: gameClient },
    React.createElement(App, null)), document.getElementById('root'));
if (module.hot) {
    module.hot.accept();
}
