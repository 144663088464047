export default class System {
    constructor(eventBus, entityManager, random) {
        this.requiredComponentIds = [];
        this.eventBus = eventBus;
        this.random = random;
        this.entityManager = entityManager;
        this.entityIds = [];
        this.onAddEntity = this.onAddEntity.bind(this);
        this.onRemoveEntity = this.onRemoveEntity.bind(this);
        this.onAddComponent = this.onAddComponent.bind(this);
        this.onRemoveComponent = this.onRemoveComponent.bind(this);
        this.eventBus.addListener('ADD_ENTITY', this.onAddEntity);
        this.eventBus.addListener('REMOVE_ENTITY', this.onRemoveEntity);
        this.eventBus.addListener('ADD_COMPONENT', this.onAddComponent);
        this.eventBus.addListener('REMOVE_COMPONENT', this.onRemoveComponent);
    }
    update(gameTime) { }
    onAddEntity(entity) {
        if (!this.requiredComponentIds.length) {
            return false;
        }
        const entityHasComponents = this.requiredComponentIds.every((componentId) => {
            return Boolean(entity.getComponentById(componentId));
        });
        if (!entityHasComponents) {
            return false;
        }
        if (this.entityIds.includes(entity.id)) {
            return false;
        }
        this.entityIds.push(entity.id);
        return true;
    }
    onRemoveEntity(entity) {
        const entityIdIndex = this.entityIds.indexOf(entity.id);
        if (entityIdIndex === -1) {
            return false;
        }
        this.entityIds.splice(entityIdIndex, 1);
        return true;
    }
    onAddComponent({ entityId }) {
        if (!this.requiredComponentIds.length) {
            return;
        }
        const entity = this.entityManager.getEntity(entityId);
        if (!entity) {
            return;
        }
        this.onAddEntity(entity);
    }
    // TODO: test this works,
    // TODO: is this needed?
    // TODO: listen on remove and add component events...
    onRemoveComponent({ entityId }) {
        if (!this.requiredComponentIds.length) {
            return;
        }
        const entity = this.entityManager.getEntity(entityId);
        if (!entity) {
            return;
        }
        // Check all components still exist
        const entityHasComponents = this.requiredComponentIds.every((componentId) => {
            return Boolean(entity.getComponentById(componentId));
        });
        // If the entity does NOT have all the required components then remove it
        if (!entityHasComponents) {
            this.onRemoveEntity(entity);
        }
    }
}
