import Component from '../ecs/component';
export default class EquipmentComponent extends Component {
    constructor({ items, slots, misc }) {
        super();
        this.$id = 'equipment';
        this.items = [];
        this.slots = {};
        this.misc = {
            lifeCrystals: 0,
        };
        this.items = items || [];
        this.slots = slots || {};
        if (misc) {
            this.misc = {
                ...this.misc,
                ...misc,
            };
        }
    }
    serialize(entityManager, output) {
        const data = super.serialize(entityManager, output);
        this.items.forEach((itemId) => {
            const entity = entityManager.getEntity(itemId);
            if (entity) {
                entity.serialize(entityManager, output);
            }
        });
        return data;
    }
}
EquipmentComponent.id = 'equipment';
