import Component from '../ecs/component';
// TODO: list all possible UI progress events...
export default class ProgressComponent extends Component {
    constructor({ zones } = {}) {
        super();
        this.$id = 'progress';
        this.zones = zones || {
            the_meadow: true,
        };
    }
}
ProgressComponent.id = 'progress';
