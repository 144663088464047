// TODO: hookup the events args
export default class EventBus {
    constructor() {
        this.listeners = {};
        this.eventQueues = [];
        this.eventQueues.push([]);
        this.eventQueues.push([]);
        this.activeQueueIndex = 0;
    }
    addListener(eventId, cb) {
        let eventListeners;
        eventListeners = this.listeners[eventId];
        if (!eventListeners) {
            eventListeners = [];
            this.listeners[eventId] = eventListeners;
        }
        eventListeners.push(cb);
    }
    removeListener(eventId, cb) {
        let eventListeners;
        eventListeners = this.listeners[eventId];
        if (!eventListeners) {
            return;
        }
        const index = eventListeners.findIndex(cb);
        eventListeners.splice(index, 1);
    }
    fire(eventId, eventValue) {
        if (eventId !== '*') {
            this.fire('*', eventValue);
        }
        let eventListeners;
        eventListeners = this.listeners[eventId];
        if (!eventListeners) {
            return;
        }
        eventListeners.forEach((cb) => cb(eventValue));
    }
    queue(eventId, eventValue) {
        const queue = this.eventQueues[this.activeQueueIndex];
        queue.push({ eventId, value: eventValue });
    }
    update() {
        const currentIndex = this.activeQueueIndex;
        // Cycle between the queue indexes...
        this.activeQueueIndex =
            (this.activeQueueIndex + 1) % this.eventQueues.length;
        this.eventQueues[this.activeQueueIndex] = [];
        // TODO: handle queue flushing
        this.eventQueues[currentIndex].forEach((event) => {
            this.fire(event.eventId, event.value);
        });
    }
}
