import Component from '../ecs/component';
export default class ItemComponent extends Component {
    constructor({ id, itemClass, level, name, rarity, tags, modifiers, }) {
        super();
        this.$id = 'item';
        this.id = id;
        this.itemClass = itemClass;
        this.level = level;
        this.name = name;
        this.rarity = rarity;
        this.tags = tags || {};
        this.modifiers = modifiers || [];
    }
}
ItemComponent.id = 'item';
