import System from '../ecs/system';
export default class LifeSystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.requiredComponentIds = ['stats'];
        this.onEntityHit = this.onEntityHit.bind(this);
        this.eventBus.addListener('ENTITY_HIT', this.onEntityHit);
        this.onUseLifeCrystal = this.onUseLifeCrystal.bind(this);
        this.eventBus.addListener('USE_LIFE_CRYSTAL', this.onUseLifeCrystal);
    }
    update(gameTime) {
        super.update(gameTime);
        this.entityIds.forEach((entityId) => {
            const entity = this.entityManager.getEntity(entityId);
            const stats = entity.get('stats');
            // Calculate life regen...
            const maxLife = stats.get('maxLife');
            const lifeRegenPerSecond = stats.get('lifeRegen') * maxLife;
            stats.add('life', lifeRegenPerSecond * gameTime.secondRatio, maxLife);
        });
    }
    onEntityHit({ originId, targetId, damageRolls, }) {
        // originId is the entity that hit the target...
        // TODO: the entity that last hit the mob so that we can award xp to it...
        // Take damage...
        const target = this.entityManager.getEntity(targetId);
        if (!target) {
            return;
        }
        const targetStats = target.get('stats');
        targetStats.sub('life', damageRolls.totalDamage);
        // Remove the entity here...
        if (targetStats.get('life') > 0) {
            return;
        }
        if (targetStats.get('life') > 0) {
            return;
        }
        const isMonster = target.get('mob');
        const isPlayer = target.get('player');
        if (isMonster) {
            this.eventBus.fire('MONSTER_KILLED', {
                entityId: target.id,
            });
        }
        if (isPlayer) {
            this.eventBus.fire('PLAYER_KILLED', {
                entityId: target.id,
            });
        }
    }
    onUseLifeCrystal({ entityId, }) {
        const entity = this.entityManager.getEntity(entityId);
        if (!entity) {
            return;
        }
        // Get the equipment
        const equipment = entity.get('equipment');
        // If we have zero or less crystals, then stop
        if (equipment.misc.lifeCrystals <= 0) {
            return;
        }
        const stats = entity.get('stats');
        const maxLife = stats.get('maxLife');
        // If we are at max life already, then stop
        if (stats.get('life') === maxLife) {
            return;
        }
        equipment.misc.lifeCrystals -= 1;
        stats.add('life', 0.25 * maxLife, maxLife);
    }
}
