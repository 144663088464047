import clsx from 'clsx';
import React from 'react';
import { useGameClient } from '../../../api/gameClientProvider';
import { GAME_EVENTS } from '../../../api/gameEvents';
import { useCursorDropState } from '../../../components/cursorProvider';
const InventorySlot = ({ children, slot, occupied, }) => {
    const gameClient = useGameClient();
    const [, drop] = useCursorDropState(() => ({
        canDrop: (item) => true,
        drop: (item) => {
            gameClient.sendServerEvent(GAME_EVENTS.DROP_ITEM, {
                inventorySlot: slot,
            });
            return occupied;
        },
    }), [slot]);
    return (React.createElement("div", { ref: drop, className: clsx('p-1 border-inset border-2 border-gray-300 bg-gray-700', {
            'inventory-slot--empty': !Boolean(occupied),
        }) }, children));
};
export default InventorySlot;
