import clsx from 'clsx';
import React from 'react';
import { useGameClient } from '../../api/gameClientProvider';
import { GAME_EVENTS } from '../../api/gameEvents';
import { useCursorState } from '../../components/cursorProvider';
import Item from './item';
const ItemClickable = ({ entity, className, }) => {
    // TODO: track selected item...
    const gameClient = useGameClient();
    const [cursorState, itemRef] = useCursorState(() => ({
        item: entity,
        onSelect: () => {
            console.log('on select!');
            gameClient.sendServerEvent(GAME_EVENTS.PICKUP_ITEM, {
                itemId: entity.id,
            });
        },
    }), [entity]);
    if (cursorState.isSelected) {
        return null;
    }
    return (React.createElement(Item, { itemRef: itemRef, className: clsx(className, 'cursor-pointer'), entity: entity }));
};
export default ItemClickable;
