import Polyglot from 'node-polyglot';
import dictionary from './dictionary';
import { translateModifier } from './i18n.utils';
import MODIFIERS_I18N from './translations/modifiers.i18n';
import STATS_I18N from './translations/stats.i18n';
const polyglot = new Polyglot({
    interpolation: {
        prefix: '{{',
        suffix: '}}',
    },
});
polyglot.extend(dictionary);
export default {
    get(...args) {
        return polyglot.t(args.join('.'));
    },
    t(path, data = {}) {
        return polyglot.t(path.join('.'), data);
    },
    modifiers(modifiers) {
        const translations = [];
        modifiers.forEach((modifier) => {
            translations.push(...translateModifier(modifier, MODIFIERS_I18N));
        });
        return translations.map((translation) => polyglot.t('_modifier', {
            ...translation.parameters,
            _: translation.template,
        }));
    },
    stats(stats) {
        const translations = [];
        const modifier = {
            stats: [],
        };
        Object.entries(stats.current).forEach(([stat, value]) => {
            modifier.stats.push({
                id: stat,
                value,
                baseValue: stats.base[stat],
            });
        });
        translations.push(...translateModifier(modifier, STATS_I18N));
        return translations.map((translation) => polyglot.t('_stat', {
            ...translation.parameters,
            _: translation.template,
        }));
    },
};
