import { EngineTimer } from '@local/engine';
import copy from 'fast-copy';
import LootTable from '../utils/lootTable';
export default class ZoneFactory {
    static loadZones(zones) {
        ZoneFactory.ZONE_BLUEPRINTS = {};
        zones.forEach((zone) => {
            const blueprint = {
                id: zone.id,
                name: zone.name,
                events: zone.events,
            };
            ZoneFactory.ZONE_BLUEPRINTS[blueprint.id] = copy(blueprint);
        });
    }
    static genZoneById(zoneId, random) {
        const zoneBlueprint = this.ZONE_BLUEPRINTS[zoneId];
        if (!zoneBlueprint) {
            return null;
        }
        const zone = {
            id: zoneBlueprint.id,
            name: zoneBlueprint.name,
            currentEventId: 0,
            events: [],
            eventTimer: new EngineTimer(0),
        };
        // Walk events and prepare them...
        const events = zoneBlueprint.events.map((event) => {
            const nextEvent = this.EVENT_BLUEPRINT_TO_EVENT[event.type](random, event);
            nextEvent.done = false;
            nextEvent.progress = 0;
            if (!nextEvent.target) {
                nextEvent.target = 0;
            }
            return nextEvent;
        });
        zone.events = events;
        return zone;
    }
    // Convert to hashmap with functions...
    static serializeEventToProgressEvent(event) {
        const progressEvent = {
            type: event.type,
        };
        if (event.type === 'unlockZones') {
            progressEvent.zoneIds = event.zones;
        }
        return progressEvent;
    }
}
ZoneFactory.ZONE_BLUEPRINTS = {};
ZoneFactory.EVENT_BLUEPRINT_TO_EVENT = {
    spawnMobs(random, event) {
        return {
            type: 'spawnMobs',
            target: event.quantity,
            mobs: new LootTable(random, event.mobs),
        };
    },
    spawnItems(random, event) {
        return {
            type: 'spawnItems',
            target: event.quantity,
            items: new LootTable(random, event.items),
        };
    },
    unlockZones(random, event) {
        return {
            type: 'unlockZones',
            zones: event.zones,
        };
    },
};
