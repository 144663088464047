import EquipableComponent from '../components/equipableComponent';
import ItemComponent from '../components/itemComponent';
import StatsComponent from '../components/statsComponent';
import Entity from '../ecs/entity';
import LootTable from '../utils/lootTable';
import ModifierFactory from './modifierFactory';
import StatFactory from './statFactory';
export default class ItemFactory {
    static loadItems(items) {
        items.forEach((item) => {
            this.ITEMS[item.id] = item;
            item.tags.forEach((tag) => {
                if (!this.TAGGED_POOLS[tag]) {
                    this.TAGGED_POOLS[tag] = [];
                }
                this.TAGGED_POOLS[tag].push(item.id);
            });
        });
    }
    static genAffixCountByRarity(random, rarity) {
        let prefixCount = 0;
        let suffixCount = 0;
        if (rarity === 'magic') {
            prefixCount = random.range(0, 1);
            suffixCount = random.range(0, 1);
            // If there is NO prefix count, then there must be a suffix
            if (!prefixCount) {
                suffixCount = Math.max(suffixCount, 1);
            }
        }
        else if (rarity === 'rare') {
            prefixCount = random.range(1, 3);
            suffixCount = random.range(1, 3);
        }
        return [prefixCount, suffixCount];
    }
    static findRandomItemByTag(random, tag, source) {
        const searchPool = this.TAGGED_POOLS[tag];
        if (!searchPool) {
            return null;
        }
        const lootTable = new LootTable(random, []);
        searchPool.forEach((itemId) => {
            const item = this.ITEMS[itemId];
            if (!item) {
                return;
            }
            if (item.minLevel > source.level) {
                return;
            }
            lootTable.addDrop({
                weight: 100,
                itemId,
            });
        });
        const { itemId } = lootTable.roll() || {};
        if (!itemId) {
            return null;
        }
        const itemBase = this.ITEMS[itemId];
        return itemBase;
    }
    static findRandomItemByLevel(random, iLevel) {
        const lootTable = new LootTable(random, []);
        Object.values(this.ITEMS).forEach((item) => {
            if (item.minLevel > iLevel) {
                return;
            }
            // Make higher level drops more likely by being relative to the iLevel
            // if(item.maxLevel < source.iLevel){ }
            lootTable.addDrop({
                weight: 100,
                itemId: item.id,
            });
        });
        const { itemId } = lootTable.roll() || {};
        if (!itemId) {
            return null;
        }
        const itemBase = this.ITEMS[itemId];
        return itemBase;
    }
    static genItemStats(random, baseItem) {
        const baseStats = {};
        baseItem.stats.forEach((stat) => {
            const roll = random.range(stat.min, stat.max);
            if (!roll) {
                return;
            }
            baseStats[stat.stat] = roll;
        });
        return new StatsComponent({ base: baseStats });
    }
    // This is a mutative operation that adds modifiers to the item
    static genItemModifiers(random, item, prefixCount, suffixCount) {
        const modifiers = [];
        for (let i = 0; i < prefixCount; i += 1) {
            // Roll for prefix...
            const modifier = ModifierFactory.findRandomModifier(random, 'prefix', item);
            if (modifier) {
                // eslint-disable-next-line no-param-reassign
                item.tags[modifier.group] = true;
                modifiers.push(modifier);
            }
        }
        for (let i = 0; i < suffixCount; i += 1) {
            // Roll for suffix...
            const modifier = ModifierFactory.findRandomModifier(random, 'suffix', item);
            if (modifier) {
                // eslint-disable-next-line no-param-reassign
                item.tags[modifier.group] = true;
                modifiers.push(modifier);
            }
        }
        const rolledModifiers = [];
        // Roll the modifiers...
        modifiers.forEach((modifier) => {
            const rolledModifier = {
                id: modifier.id,
                type: modifier.type,
                stats: [],
            };
            modifier.stats.forEach((stat) => {
                rolledModifier.stats.push({
                    id: stat.stat,
                    value: random.range(stat.min, stat.max),
                });
            });
            rolledModifiers.push(rolledModifier);
        });
        return rolledModifiers;
    }
    static genRandomItemByLevel(random, rarity, iLevel) {
        const baseItem = this.findRandomItemByLevel(random, iLevel);
        if (!baseItem) {
            return null;
        }
        const item = {
            id: baseItem.id,
            name: baseItem.name,
            itemClass: baseItem.class,
            // set item level based off source,
            level: iLevel,
            rarity,
            modifiers: [],
            tags: {},
        };
        baseItem.tags.forEach((tagId) => {
            item.tags[tagId] = true;
        });
        // roll for modifiers...
        // if the item has specified implicit, or modifiers
        const [prefixCount, suffixCount] = this.genAffixCountByRarity(random, item.rarity);
        item.modifiers = this.genItemModifiers(random, item, prefixCount, suffixCount);
        const stats = this.genItemStats(random, baseItem);
        StatFactory.applyModifiers(stats, [
            StatFactory.getLocalModifier(item.modifiers),
        ]);
        const itemEntity = new Entity();
        itemEntity.addComponent(new ItemComponent(item));
        // TODO: decide when it is an "equipable" item
        itemEntity.addComponent(new EquipableComponent({
            slots: [...baseItem.slots],
            blocks: [...baseItem.blocks],
        }));
        itemEntity.addComponent(stats);
        return itemEntity;
    }
}
ItemFactory.ITEMS = {};
ItemFactory.TAGGED_POOLS = {};
