import MobComponent from '../components/mobComponent';
import StatsComponent from '../components/statsComponent';
import TargetComponent from '../components/targetComponent';
import Entity from '../ecs/entity';
export default class MobFactory {
    static loadMobs(mobs) {
        mobs.forEach((mob) => {
            this.MOBS[mob.id] = mob;
        });
    }
    static genMobById(mobId, random) {
        const mobBase = this.MOBS[mobId];
        if (!mobBase) {
            return null;
        }
        // Generate random monster stats...
        // Monster affixes?
        const stats = {};
        Object.entries(mobBase.stats).forEach(([stat, value]) => {
            stats[stat] = random.range(...value);
        });
        // Force the mob life to start at the max life
        if (stats.life && stats.maxLife) {
            stats.life = stats.maxLife;
        }
        const mob = new Entity();
        mob.addComponent(new MobComponent({
            id: mobBase.id,
            name: mobBase.name,
        }));
        mob.addComponent(new StatsComponent({
            base: stats,
        }));
        mob.addComponent(new TargetComponent());
        return mob;
    }
}
MobFactory.MOBS = {};
