import ZoneComponent from '../components/zoneComponent';
import System from '../ecs/system';
import ItemFactory from '../factories/itemFactory';
import LootTable from '../utils/lootTable';
export default class LootSystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.onMonsterKilled = this.onMonsterKilled.bind(this);
        this.eventBus.addListener('MONSTER_KILLED', this.onMonsterKilled);
        this.quantityTable = new LootTable(this.random, [
            {
                weight: 62,
                quantity: 0,
            },
            {
                weight: 38,
                quantity: 1,
            },
        ]);
        this.rarityTable = new LootTable(this.random, [
            {
                weight: 82,
                rarity: 'normal',
            },
            {
                weight: 10,
                rarity: 'magic',
            },
            {
                weight: 5,
                rarity: 'rare',
            },
        ]);
    }
    onMonsterKilled({ entityId }) {
        // TODO: track WHO hit the monster last, this way we can get their magic find...
        const mob = this.entityManager.getEntity(entityId);
        const mobStats = mob.get('stats');
        // if mob has loot table, roll by loot table...
        // IF an item is dropped, then search add it to the entity list
        const items = this.rollLootByLevel(mobStats);
        if (items.length) {
            items.forEach((item) => {
                item.addComponent(new ZoneComponent());
                this.eventBus.queue('ADD_ENTITY', item);
            });
            // TODO: change to "ADD_DROP"?
        }
        // find all players, add lifeCrystals to each player
        const players = this.entityManager.getEntitiesWith(['player']);
        players.forEach((player) => {
            const playerEquipment = player.get('equipment');
            playerEquipment.misc.lifeCrystals += mobStats.get('lifeCrystals');
        });
        this.eventBus.fire('REMOVE_ENTITY', mob);
    }
    rollLootByLevel(sourceStats) {
        // TODO: increase quantity roll based on stats
        const { quantity } = this.quantityTable.roll();
        if (quantity <= 0) {
            return [];
        }
        const items = [];
        for (let i = 0; i < quantity; i += 1) {
            // while drops remaining, then
            const { rarity } = this.rarityTable.roll();
            const item = ItemFactory.genRandomItemByLevel(this.random, rarity, sourceStats.get('iLevel'));
            if (item) {
                items.push(item);
            }
        }
        return items;
    }
}
LootSystem.RARITY_FALLBACK = {
    '': '',
    normal: '',
    magic: 'normal',
    rare: 'magic',
};
