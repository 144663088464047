import React, { useMemo } from 'react';
import { useGameClient } from '../../api/gameClientProvider';
import { GAME_EVENTS } from '../../api/gameEvents';
import Button from '../../components/button';
import Card from '../../components/card';
import i18n from '../../i18n/i18n';
const ZONE_LIST = ['the_meadow', 'the_cave'];
const ZoneSelect = ({ zones }) => {
    const gameClient = useGameClient();
    const zoneButtons = useMemo(() => ZONE_LIST.map((zoneId) => (React.createElement(Button, { primary: true, disabled: !zones[zoneId], onClick: () => gameClient.sendServerEvent(GAME_EVENTS.LOAD_ZONE, {
            zoneId,
        }) }, i18n.get('zone', zoneId)))), [zones]);
    return (React.createElement(Card, { className: "flex-col h-full space-y-2 overflow-y-scroll" }, zoneButtons));
};
export default ZoneSelect;
