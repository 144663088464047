export default [
    {
        id: 'the_meadow',
        name: 'The Meadow',
        events: [
            {
                type: 'spawnMobs',
                quantity: 15,
                mobs: [
                    { weight: 60, mobId: 'goobin' },
                    { weight: 30, mobId: 'zombie' },
                    { weight: 30, mobId: 'imp' },
                ],
            },
            {
                type: 'spawnMobs',
                quantity: 10,
                mobs: [
                    { weight: 30, mobId: 'goobin' },
                    { weight: 60, mobId: 'zombie' },
                    { weight: 30, mobId: 'imp' },
                ],
            },
            {
                type: 'unlockZones',
                zones: ['the_cave'],
            },
            {
                type: 'spawnMobs',
                quantity: 25,
                mobs: [
                    { weight: 70, mobId: 'goobin' },
                    { weight: 20, mobId: 'goobin_berserker' },
                ],
            },
            {
                type: 'unlockZones',
                zones: ['the_cave'],
            },
        ],
    },
    {
        id: 'the_cave',
        name: 'The Cave',
        events: [
            {
                type: 'spawnMobs',
                mobs: [{ weight: 10, mobId: 'goobin_berserker' }],
                quantity: 25,
            },
            {
                type: 'unlockZones',
                zones: ['the_meadow'],
            },
        ],
    },
];
