import React, { useEffect, useReducer } from 'react';
import { applyPatch } from 'rfc6902';
import { useGameClient } from './api/gameClientProvider';
import { CursorProvider } from './components/cursorProvider';
import { GameStateProvider } from './components/gameStateProvider';
import GameUi from './game/gameUi';
// Load the application state and connect to the game room...
const gameReducer = (state, action) => {
    if (action.type === 'render') {
        const nextState = {
            ...state,
        };
        nextState.isLoaded = true;
        applyPatch(nextState.world, action.data.patch);
        nextState.entities = Object.values(nextState.world);
        nextState.player = nextState.world[action.data.playerEntityId];
        return nextState;
    }
    return state;
};
const Game = () => {
    const gameClient = useGameClient();
    // TODO: trigger is loaded from another location...
    // TODO: trial redux for state management
    const [gameState, dispatch] = useReducer(gameReducer, {
        isLoaded: false,
        player: undefined,
        world: {},
        entities: [],
    });
    useEffect(() => {
        gameClient.join();
        gameClient.addServerEventListener((eventId, eventValue) => {
            dispatch({ type: eventId, data: eventValue });
        });
        return () => {
            gameClient.leave();
        };
    }, []);
    // Render the loading ui...
    if (!gameState.isLoaded) {
        return React.createElement("div", null, "Loading...");
    }
    return (React.createElement(GameStateProvider, { gameState: gameState },
        React.createElement(CursorProvider, { gameState: gameState },
            React.createElement(GameUi, null))));
};
export default Game;
