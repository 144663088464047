import Component from '../ecs/component';
// TODO: rename to Character
export default class PlayerComponent extends Component {
    constructor({ characterId, heldEntityId, }) {
        super();
        this.$id = 'player';
        this.characterId = characterId;
        this.heldEntityId = heldEntityId;
    }
    serialize(entityManager, output) {
        const data = super.serialize(entityManager, output);
        if (this.heldEntityId) {
            const entity = entityManager.getEntity(this.heldEntityId);
            if (entity) {
                entity.serialize(entityManager, output);
            }
        }
        return data;
    }
}
PlayerComponent.id = 'player';
