export default class Engine {
    constructor(targetTimeStep = Engine.FRAME_RATE) {
        this.isRunning = false;
        this.targetTimeStep = targetTimeStep;
    }
    start() {
        let time = 0;
        const deltaTime = this.targetTimeStep;
        let previousTime = 0;
        let currentTime = 0;
        let elapsedTime = 0;
        let lagTime = 0;
        let alpha = 0;
        const loop = () => {
            currentTime = this.getTime();
            elapsedTime = currentTime - previousTime;
            previousTime = currentTime;
            // Sum the lag time, then update until we are below the delta time
            // this ensures we update at a fixed timestep
            lagTime += elapsedTime;
            while (lagTime > deltaTime) {
                this.update({
                    delta: deltaTime,
                    time,
                    secondRatio: deltaTime / Engine.SECOND_MS_LENGTH,
                    secondsLength: Engine.SECOND_MS_LENGTH,
                });
                time += deltaTime;
                lagTime -= deltaTime;
            }
            alpha = lagTime / deltaTime;
            this.render(alpha);
            setTimeout(loop, deltaTime);
        };
        this.onStart();
        previousTime = this.getTime();
        loop();
    }
    update(gameTime) { }
    render(alpha) { }
    stop() {
        this.isRunning = false;
    }
    getTime() {
        return Date.now();
    }
    // to implement
    // TODO: rename to initialize
    onStart() {
        this.isRunning = true;
    }
    onStop() { }
}
Engine.SECOND_MS_LENGTH = 1000;
Engine.FRAME_RATE = 1000 / 30;
