import React from 'react';
import { useCursorState } from '../../components/cursorProvider';
import Item from './item';
const HeldItem = ({ className }) => {
    const [cursorState] = useCursorState(() => ({ item: undefined, onSelect: () => { } }), []);
    if (!cursorState.heldEntity) {
        return null;
    }
    return React.createElement(Item, { className: className, entity: cursorState.heldEntity });
};
export default HeldItem;
