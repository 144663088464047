import ZoneComponent from '../components/zoneComponent';
import ZoneProgressComponent from '../components/zoneProgressComponent';
import Entity from '../ecs/entity';
import System from '../ecs/system';
import MobFactory from '../factories/mobFactory';
import ZoneFactory from '../factories/zoneFactory';
/*
Zones have a length which is a number of monster spawns, when you reach the end of a zone it unlocks more zones.
A "Zone" should be an entity?
 */
const BASE_SPAWN_TIME = 3000;
export default class ZoneSystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.requiredComponentIds = [];
        this.zoneEventsMap = {};
        this.onLoadZone = this.onLoadZone.bind(this);
        this.onLeaveZone = this.onLeaveZone.bind(this);
        this.onContinueZone = this.onContinueZone.bind(this);
        this.eventBus.addListener('LOAD_ZONE', this.onLoadZone);
        this.eventBus.addListener('LEAVE_ZONE', this.onLeaveZone);
        this.eventBus.addListener('CONTINUE_ZONE', this.onContinueZone);
        this.zoneEventsMap = {
            spawnMobs: this.spawnMobs.bind(this),
            spawnItems: this.spawnItems.bind(this),
            unlockZones: this.unlockZones.bind(this),
        };
    }
    // Should spawn time be based on mobs?
    update(gameTime) {
        super.update(gameTime);
        // Check if we have a zone...
        if (!this.zone) {
            return;
        }
        // If we do check the event timer duration
        // Check the spawn rate,
        const players = this.entityManager.getEntitiesWith(['player']);
        let maxMovementSpeed = BASE_SPAWN_TIME;
        players.forEach((player) => {
            const stats = player.get('stats');
            maxMovementSpeed = Math.max(stats.get('movementSpeed'), maxMovementSpeed);
        });
        let adjustedSpawnTime = BASE_SPAWN_TIME;
        if (maxMovementSpeed !== adjustedSpawnTime) {
            adjustedSpawnTime = Math.round(BASE_SPAWN_TIME * (BASE_SPAWN_TIME / maxMovementSpeed));
        }
        if (this.zone.eventTimer.getDuration() !== adjustedSpawnTime) {
            this.zone.eventTimer.reset(adjustedSpawnTime, this.zone.eventTimer.getElapsed());
        }
        // Then update the event timer
        this.zone.eventTimer.update(gameTime);
        // Cap the maximum event id
        this.zone.currentEventId = Math.min(this.zone.currentEventId, this.zone.events.length - 1);
        const currentEvent = this.zone.events[this.zone.currentEventId];
        // Check we have an event
        if (!currentEvent) {
            return;
        }
        const eventHandler = this.zoneEventsMap[currentEvent.type];
        // TODO: throw error?
        // Check we have an event handler
        if (!eventHandler) {
            return;
        }
        // Check the event hasn't been solved
        if (!currentEvent.done) {
            currentEvent.done = eventHandler(currentEvent);
            // Cap the maximum event id
            this.zone.currentEventId = Math.min(this.zone.currentEventId, this.zone.events.length - 1);
        }
        // Update the zone progress...
        const zoneEntity = this.entityManager.getEntity('zone');
        const zoneProgress = zoneEntity.get('zoneProgress');
        zoneProgress.currentEventId = this.zone.currentEventId;
        if (currentEvent.target) {
            // Set the progress to x/100
            const delayedProgress = Math.max(currentEvent.progress - 1, 0);
            zoneProgress.currentProgress = Math.round((delayedProgress / currentEvent.target) * 100);
        }
        else {
            zoneProgress.currentProgress = 100;
        }
    }
    onLoadZone({ zoneId, entityId }) {
        // If a player is loading a zone, AND they do not have the zone unlocked, then stop
        if (entityId) {
            const player = this.entityManager.getEntity(entityId);
            const progress = player.get('progress');
            if (!progress.zones[zoneId]) {
                return;
            }
        }
        // Remove all zone entities
        const zoneEntities = this.entityManager.getEntitiesWith(['zone']);
        zoneEntities.forEach((entity) => {
            this.eventBus.fire('REMOVE_ENTITY', entity);
        });
        // Generate the zone...
        this.zone = ZoneFactory.genZoneById(zoneId, this.random);
        this.zone.eventTimer.reset(BASE_SPAWN_TIME);
        // Generate the zone entity...
        const zoneEntity = new Entity({
            id: 'zone',
        });
        zoneEntity.addComponent(new ZoneComponent());
        zoneEntity.addComponent(new ZoneProgressComponent({
            zoneId: this.zone.id,
            events: this.zone.events.map(ZoneFactory.serializeEventToProgressEvent),
        }));
        this.eventBus.fire('ADD_ENTITY', zoneEntity);
    }
    onLeaveZone(event) {
        // Remove all zone entities
        const zoneEntities = this.entityManager.getEntitiesWith(['zone']);
        zoneEntities.forEach((entity) => {
            this.eventBus.fire('REMOVE_ENTITY', entity);
        });
        this.zone = null;
    }
    onContinueZone(event) {
        // Check there is a zone
        if (!this.zone) {
            return;
        }
        const currentEvent = this.zone.events[this.zone.currentEventId];
        // Check there is an event
        if (!currentEvent) {
            return;
        }
        // Check it is a zone unlock
        if (currentEvent.type !== 'unlockZones') {
            return;
        }
        this.zone.currentEventId += 1;
    }
    // ZONE EVENTS
    spawnMobs(currentEvent) {
        const mobs = this.entityManager.getEntitiesWith(['mob']);
        if (mobs.length) {
            this.zone.eventTimer.resetToZero();
            return false;
        }
        // Once the action is going to happen again, check if we are past the process...
        if (currentEvent.progress >= currentEvent.target) {
            this.zone.currentEventId += 1;
            return true;
        }
        if (!this.zone.eventTimer.test()) {
            return false;
        }
        this.zone.eventTimer.resetToZero();
        currentEvent.progress += 1;
        // Generate a mob
        const { mobId } = currentEvent.mobs.roll();
        const monster = MobFactory.genMobById(mobId, this.random);
        if (!monster) {
            return false;
        }
        // Relate the monster to the zone
        monster.addComponent(new ZoneComponent());
        this.eventBus.fire('ADD_ENTITY', monster);
        return false;
    }
    spawnItems(event) {
        // Spawn items...
        this.zone.currentEventId += 1;
        return true;
    }
    unlockZones(event) {
        const players = this.entityManager.getEntitiesWith(['player']);
        players.forEach((player) => {
            const progress = player.get('progress');
            event.zones.forEach((zoneId) => {
                progress.zones[zoneId] = true;
            });
        });
        // Unlock zones for current players...
        return true;
    }
}
