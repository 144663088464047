import React from 'react';
import Card from '../../components/card';
import { useGameState } from '../../components/gameStateProvider';
import Mobs from './events/mobs';
import ZoneSelect from './events/zoneSelect';
const ZONE_EVENTS = {
    $default: () => React.createElement(Card, { className: "flex-col h-full" }),
    spawnMobs: () => React.createElement(Mobs, null),
    unlockZones: () => React.createElement(ZoneSelect, null),
};
const ZoneEvent = () => {
    const gameState = useGameState();
    const zoneEntity = gameState.world.zone;
    const { zoneProgress } = zoneEntity.components || {};
    if (!zoneEntity) {
        return React.createElement(Card, { className: "flex-col h-full" });
    }
    const currentEvent = zoneProgress.events[zoneProgress.currentEventId];
    const zoneEventRender = ZONE_EVENTS[currentEvent.type] || ZONE_EVENTS.$default;
    return zoneEventRender();
};
export default ZoneEvent;
