import clsx from 'clsx';
import React from 'react';
import ProgressSphere from '../../components/progressSphere';
import './uiSphere.css';
const UiSphere = ({ position, current, max, color, backgroundColor, foregroundColor, }) => {
    const className = `relative overflow-hidden ui-sphere ui-sphere--${position}`;
    return (React.createElement("div", { className: className },
        React.createElement(ProgressSphere, { className: "w-full responsive-square", color: color, current: current, max: max },
            React.createElement("div", { className: "relative w-full " },
                React.createElement("svg", { viewBox: "0 0 560 20", className: clsx('sphere-liquid-wave sphere-liquid-wave--back fill-current', backgroundColor), version: "1.1", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z" }),
                    React.createElement("path", { d: "M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z" }),
                    React.createElement("path", { d: "M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z" }),
                    React.createElement("path", { d: "M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z" })),
                React.createElement("svg", { viewBox: "0 0 560 20", className: clsx('sphere-liquid-wave sphere-liquid-wave--front fill-current', foregroundColor), version: "1.1", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z" }),
                    React.createElement("path", { d: "M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z" }),
                    React.createElement("path", { d: "M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z" }),
                    React.createElement("path", { d: "M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z" })))),
        React.createElement("div", { className: "responsive-square--half ui-sphere-connector absolute w-full bg-transparent" }),
        React.createElement("div", { className: "ui-sphere-connector--border absolute w-1/2 border-b-2 border-gray-900 dark:border-gray-400" }),
        React.createElement("div", { className: "responsive-square ui-sphere-outline absolute w-full rounded-full border-2 border-gray-800 dark:border-gray-400" })));
};
export default UiSphere;
