export default [
    {
        stats: ['local_attack_speed_+%'],
        conditions: [
            {
                max: 0,
            },
        ],
        template: '{{#0}}% Increased Attack Speed',
    },
    {
        stats: ['local_attack_speed_+%'],
        conditions: [
            {
                min: 0,
            },
        ],
        template: '{{#0}}% Reduced Attack Speed',
    },
    {
        stats: ['maxLife'],
        conditions: [],
        template: 'Adds {{#0}} to Life',
    },
    {
        stats: ['local_armour_+%'],
        conditions: [],
        template: '{{#0}}% Increased Armour',
    },
    {
        stats: ['local_physical_damage_+%'],
        conditions: [],
        template: '{{#0}}% Increased Physical Damage',
    },
    {
        stats: ['minPhysicalDamage', 'maxPhysicalDamage'],
        conditions: [],
        template: 'Adds {{#0}} to {{#1}} Physical Damage',
    },
];
