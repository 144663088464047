import React, { useMemo } from 'react';
import Card from '../../../components/card';
import { useGameState } from '../../../components/gameStateProvider';
import EntityManager from '../../utils/entityManager';
import Mob from './mob';
const Mobs = () => {
    const gameState = useGameState();
    const mobs = useMemo(() => EntityManager.getEntitiesWith(gameState.entities, ['mob', 'stats']), [gameState.entities]);
    return (React.createElement(Card, { className: "flex-col h-full" },
        mobs.map((entity) => (React.createElement("div", { key: entity.id, className: "w-full h-64" },
            React.createElement(Mob, { entity: entity })))),
        !mobs.length && (React.createElement("div", { className: "w-full h-64 flex items-center justify-center " },
            React.createElement("div", { className: "animate-spin border-l-2 border-r-2 rounded-full w-16 h-16 border-red-700" })))));
};
export default Mobs;
