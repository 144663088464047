import System from '../ecs/system';
export default class MonsterSystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.requiredComponentIds = ['mob', 'stats'];
    }
    update(gameTime) {
        super.update(gameTime);
        // get player entities...
        const players = this.entityManager.getEntitiesWith([
            'player',
            'stats',
        ]);
        this.entityIds.forEach((entityId) => {
            const entity = this.entityManager.getEntity(entityId);
            const target = entity.get('target');
            if (!players.length) {
                return;
            }
            const targetEntity = players[this.random.range(0, players.length - 1)];
            target.targetId = targetEntity.id;
        });
    }
}
