import SYSTEM_DATA from '@local/data';
import { Engine, EventBus, Random } from '@local/engine';
import EntityManager from './ecs/entityManager';
import ItemFactory from './factories/itemFactory';
import MobFactory from './factories/mobFactory';
import ModifierFactory from './factories/modifierFactory';
import StatFactory from './factories/statFactory';
import ZoneFactory from './factories/zoneFactory';
import CombatSystem from './systems/combatSystem';
import EquipmentSystem from './systems/equipmentSystem';
import InventorySystem from './systems/inventorySystem';
import LifeSystem from './systems/lifeSystem';
import LootSystem from './systems/lootSystem';
import MonsterSystem from './systems/monsterSystem';
import PlayerInputSystem from './systems/playerInputSystem';
import PlayerSystem from './systems/playerSystem';
import StatsSystem from './systems/statsSystem';
import ZoneSystem from './systems/zoneSystem';
// Instantiate data, this would be done inside a "server" object
// Once data has been instantiated can we called: delete SYSTEM_DATA.MOB_BLUEPRINTS?
ZoneFactory.loadZones(SYSTEM_DATA.ZONES);
MobFactory.loadMobs(SYSTEM_DATA.MOBS);
StatFactory.loadStats(SYSTEM_DATA.STATS);
ItemFactory.loadItems(SYSTEM_DATA.ITEMS);
ModifierFactory.loadModifiers(SYSTEM_DATA.MODIFIERS);
export default class Game extends Engine {
    constructor() {
        super();
        this.systems = [];
        this.rendering = false;
    }
    onStart() {
        super.onStart();
        // generate a random seed on start
        if (process.env.NODE_ENV === 'development') {
            this.random = new Random('random-seed');
        }
        else {
            this.random = new Random();
        }
        this.systems = [];
        this.eventBus = new EventBus();
        this.entityManager = new EntityManager(this.eventBus);
        this.systems.push(new PlayerInputSystem(this.eventBus, this.entityManager, this.random));
        this.systems.push(new ZoneSystem(this.eventBus, this.entityManager, this.random));
        this.systems.push(new StatsSystem(this.eventBus, this.entityManager, this.random));
        this.systems.push(new InventorySystem(this.eventBus, this.entityManager, this.random));
        this.systems.push(new EquipmentSystem(this.eventBus, this.entityManager, this.random));
        this.systems.push(new PlayerSystem(this.eventBus, this.entityManager, this.random));
        this.systems.push(new MonsterSystem(this.eventBus, this.entityManager, this.random));
        this.systems.push(new CombatSystem(this.eventBus, this.entityManager, this.random));
        this.systems.push(new LifeSystem(this.eventBus, this.entityManager, this.random));
        this.systems.push(new LootSystem(this.eventBus, this.entityManager, this.random));
    }
    update(gameTime) {
        super.update(gameTime);
        this.eventBus.update();
        this.systems.forEach((system) => {
            system.update(gameTime);
        });
        // Every 500 ms, save the game?
    }
    render(alpha) {
        // render at 50%Hz
        // get all zone entities,
        // serialize
        // get all players
        // serialize
        // send state to each player...
        this.rendering = !this.rendering;
        if (!this.rendering) {
            return;
        }
        const zoneState = {};
        this.entityManager
            .getEntitiesWith(['zone'])
            .forEach((entity) => entity.serialize(this.entityManager, zoneState));
        // At some point we will need to send all other players to the frontend...
        // But this must be minus their inventory, skills etc...
        const players = this.entityManager.getEntitiesWith(['player']);
        players.forEach((player) => {
            const playerState = {};
            const { characterId } = player.get('player');
            player.serialize(this.entityManager, playerState);
            this.eventBus.fire('PLAYER_STATE', {
                characterId,
                entityId: player.id,
                state: {
                    ...playerState,
                },
            });
            this.eventBus.fire('RENDER', {
                characterId,
                entityId: player.id,
                state: {
                    ...zoneState,
                    ...playerState,
                },
            });
        });
    }
}
