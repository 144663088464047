/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-var-requires */
// Iron
const iron_sword = require('./iron/iron_sword.png');
const iron_boots = require('./iron/boots.png');
const iron_chest = require('./iron/chest.png');
// Steel
const broad_sword = require('./steel/broad_sword.png');
const steel_chest = require('./steel/chest.png');
const steel_boots = require('./steel/boots.png');
const ITEM_ASSETS = {
    iron_sword,
    iron_chest,
    iron_boots,
    // Steel
    broad_sword,
    steel_chest,
    steel_boots,
};
export default ITEM_ASSETS;
