import Component from '../ecs/component';
export default class StatsComponent extends Component {
    constructor({ base, current }) {
        super();
        this.$id = 'stats';
        this.base = {};
        this.current = {};
        if (base) {
            this.base = {
                ...this.base,
                ...base,
            };
        }
        // Load our calculated values...
        if (current) {
            this.current = {
                ...current,
            };
        }
        else {
            this.current = {
                ...this.base,
            };
        }
    }
    // Util methods
    get(id) {
        return this.current[id] || 0;
    }
    sub(id, value) {
        if (this.current[id] === undefined) {
            return;
        }
        this.current[id] -= value;
    }
    add(id, value, maxLimit) {
        // Can max limit look for a property?
        if (this.current[id] === undefined) {
            return;
        }
        this.current[id] += value;
        if (maxLimit !== undefined && this.current[id] > maxLimit) {
            this.current[id] = maxLimit;
        }
    }
    set(id, value) {
        if (this.current[id] === undefined) {
            return;
        }
        this.current[id] = value;
    }
    forEach(fn) {
        Object.keys(this.base).forEach(fn);
    }
}
StatsComponent.id = 'stats';
