import clsx from 'clsx';
import React from 'react';
const ProgressBar = ({ className, color, current, max, }) => {
    let progress = ((current / max) * 100).toPrecision(4);
    if (current <= 0) {
        progress = '0';
    }
    return (React.createElement("div", { className: clsx('overflow-hidden h-3 rounded-sm flex', className) },
        React.createElement("div", { style: { width: `${progress}%` }, className: clsx('transition-all duration-300 shadow-none flex', color) })));
};
export default ProgressBar;
