import Component from '../ecs/component';
// TODO: treat as a 2d grid, allow for moving items around it.
export default class InventoryComponent extends Component {
    constructor({ items }) {
        super();
        this.$id = 'inventory';
        this.items = [];
        // Inventories are limited to 24 items...
        this.items = new Array(24).fill('');
        if (items) {
            this.items.splice(0, items.length, ...items);
        }
    }
    size() {
        return this.items.length;
    }
    used() {
        return this.items.filter(Boolean).length;
    }
    serialize(entityManager, output) {
        const data = super.serialize(entityManager, output);
        this.items.forEach((itemId) => {
            const entity = entityManager.getEntity(itemId);
            if (entity) {
                entity.serialize(entityManager, output);
            }
        });
        return data;
    }
}
InventoryComponent.id = 'inventory';
