import React from 'react';
const OPERATIONS_LIST = {
    numerator: (statValue, operatorValue) => Number(operatorValue / statValue).toFixed(2),
};
function applyTransform(translation, statValue, index) {
    if (!translation.transforms) {
        return statValue;
    }
    if (!translation.transforms[index]) {
        return statValue;
    }
    const transform = translation.transforms[index];
    if (!transform) {
        return statValue;
    }
    const transformFn = OPERATIONS_LIST[transform.type];
    if (!transformFn) {
        return statValue;
    }
    return transformFn(statValue, transform.value);
}
export function translateModifier(modifier, translationTemplates) {
    let remainingStats = {};
    modifier.stats.forEach((stat) => {
        remainingStats[stat.id] = stat;
    });
    const translations = [];
    translationTemplates.forEach((translation) => {
        const stats = [];
        const workingStats = {
            ...remainingStats,
        };
        translation.stats.forEach((statId) => {
            if (workingStats[statId]) {
                stats.push(workingStats[statId]);
                delete workingStats[statId];
            }
        });
        if (stats.length !== translation.stats.length) {
            return;
        }
        const isPassedConditions = stats.every((stat, index) => {
            const condition = translation.conditions[index];
            if (!condition) {
                return true;
            }
            if (condition.min !== undefined && stat.value >= condition.min) {
                return true;
            }
            if (condition.max !== undefined && stat.value <= condition.max) {
                return true;
            }
            return false;
        });
        if (!isPassedConditions) {
            return;
        }
        let isAlt = false;
        const parameters = {};
        stats.forEach((stat, index) => {
            if (stat.baseValue !== undefined && stat.baseValue !== stat.value) {
                isAlt = true;
            }
            const value = applyTransform(translation, stat.value, index);
            parameters[`#${index}`] = Math.abs(value);
        });
        remainingStats = {
            ...workingStats,
        };
        if (isAlt && translation.altTemplate) {
            translations.push({
                template: translation.altTemplate,
                parameters,
            });
        }
        else {
            translations.push({
                template: translation.template,
                parameters,
            });
        }
    });
    return translations;
}
const STYLE_TO_CLASS = {
    'blue::': 'text-blue-300',
};
const STYLE_EXTRACTION_REGEX = /(blue::)\((.+)\)/g;
export function styleI18nText(text) {
    let currentClassName = '';
    const components = [];
    const parts = text.split(STYLE_EXTRACTION_REGEX);
    parts.forEach((token, index) => {
        if (!token) {
            return;
        }
        if (STYLE_TO_CLASS[token]) {
            currentClassName = STYLE_TO_CLASS[token];
            return;
        }
        components.push(React.createElement("span", { className: currentClassName }, token));
    });
    return components;
}
