import React, { useMemo } from 'react';
import { useGameState } from '../../../components/gameStateProvider';
import ItemClickable from '../../ui/itemClickable';
import EntityManager from '../../utils/entityManager';
import InventorySlot from './inventorySlot';
const InventorySlots = () => {
    const gameState = useGameState();
    const items = useMemo(() => gameState.player.components.inventory.items.map((itemId) => {
        if (!itemId) {
            return '';
        }
        return EntityManager.getEntity(gameState.world, itemId);
    }), [gameState.entities, gameState.player.components.inventory]);
    return (React.createElement("div", { className: "w-full grid grid-flow-row grid-cols-4 sm:grid-cols-6 lg:grid-cols-8 grid-rows-6" }, items.map((item, index) => {
        if (!item) {
            return (React.createElement(InventorySlot, { key: `inventory-${index}`, slot: index }));
        }
        return (React.createElement(InventorySlot, { key: item.id, slot: index, occupied: item },
            React.createElement(ItemClickable, { className: "w-full h-auto", entity: item })));
    })));
};
export default InventorySlots;
