import clsx from 'clsx';
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import i18n from '../../i18n/i18n';
import { styleI18nText } from '../../i18n/i18n.utils';
const ItemStats = ({ tooltipId, entity }) => {
    const { stats, item } = entity.components;
    const description = useMemo(() => {
        const temp = [];
        const baseStats = i18n.stats(stats);
        const modifiers = i18n.modifiers(item.modifiers);
        if (baseStats.length) {
            temp.push(React.createElement("div", { key: `${tooltipId}-base`, className: "text-center font-medium py-1" },
                React.createElement("div", { className: "py-1" }, baseStats.map((text, index) => (React.createElement("p", { key: `${tooltipId}-base-${index}` }, styleI18nText(text)))))));
        }
        if (modifiers.length) {
            if (temp.length) {
                temp.push(React.createElement("div", { className: "border-b-2 border-gray-300" }));
            }
            temp.push(React.createElement("div", { key: `${tooltipId}-modifiers`, className: "text-center font-medium py-1 text-blue-300" },
                React.createElement("div", { className: "py-1" }, modifiers.map((text, index) => (React.createElement("p", { key: `${tooltipId}-modifier-${index}` }, text))))));
        }
        return temp;
    }, [stats, item.modifiers]);
    return (React.createElement(ReactTooltip, { id: tooltipId, place: "top", effect: "solid" },
        React.createElement("div", null,
            React.createElement("div", { className: clsx('text-center text-xl font-medium border-b-2 py-1', {
                    'border-gray-300 text-white': item.rarity === 'normal',
                    'border-blue-300 text-blue-300': item.rarity === 'magic',
                    'border-yellow-300 text-yellow-300': item.rarity === 'rare',
                    'border-red-300 text-red-300': item.rarity === 'legendary',
                }) }, item.name),
            description)));
};
export default ItemStats;
