import React from 'react';
import { useGameState } from '../components/gameStateProvider';
import Town from './town/town';
import Zone from './zone/zone';
const GameBackground = () => {
    const gameState = useGameState();
    const { zone } = gameState.world;
    if (zone) {
        return React.createElement(Zone, null);
    }
    return React.createElement(Town, null);
};
export default GameBackground;
