import React from 'react';
import { useGameClient } from '../../api/gameClientProvider';
import ICON_ASSETS from '../../assets/images/icons';
import Button from '../../components/button';
import i18n from '../../i18n/i18n';
const DeathModal = () => {
    const gameClient = useGameClient();
    const onClick = () => {
        gameClient.sendServerEvent('RESPAWN');
    };
    return (React.createElement("div", { className: "fixed z-10 inset-0 overflow-y-auto", "aria-labelledby": "modal-title", role: "dialog", "aria-modal": "true" },
        React.createElement("div", { className: "flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" },
            React.createElement("div", { className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity", "aria-hidden": "true" }),
            React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen", "aria-hidden": "true" }, "\u200B"),
            React.createElement("div", { className: "inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" },
                React.createElement("div", { className: "bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4" },
                    React.createElement("div", { className: "sm:flex sm:items-start" },
                        React.createElement("div", { className: "mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-red-100 sm:mx-0 sm:h-20 sm:w-20" },
                            React.createElement("img", { src: ICON_ASSETS.death, alt: "death" })),
                        React.createElement("div", { className: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" },
                            React.createElement("h3", { className: "text-lg leading-6 font-medium text-gray-900 dark:text-white", id: "modal-title" }, "You Died!"),
                            React.createElement("div", { className: "mt-2" },
                                React.createElement("p", { className: "text-sm text-gray-500 dark:text-gray-200" }, i18n.get('ui.death.messageLine1')),
                                React.createElement("p", { className: "text-sm text-gray-500 dark:text-gray-200" }, i18n.get('ui.death.messageLine2')))))),
                React.createElement("div", { className: "bg-gray-50 dark:bg-gray-900 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse" },
                    React.createElement(Button, { primary: true, className: "w-full", onClick: onClick }, "Enter Garden"))))));
};
export default DeathModal;
