export default class LootTable {
    constructor(random, items = []) {
        this.table = [];
        this.random = random;
        this.table = [];
        this.size = 0;
        items.forEach(this.addDrop.bind(this));
    }
    addDrop(drop) {
        const internal = {
            dropStart: 0,
            dropEnd: 0,
            item: {
                ...drop,
            },
        };
        internal.dropStart = this.size + 1;
        internal.dropEnd = this.size + internal.item.weight;
        this.size = internal.dropEnd;
        this.table.push(internal);
    }
    roll() {
        const roll = this.random.range(1, this.size);
        const drop = this.findDrop(roll);
        return drop;
    }
    hasDrops() {
        return this.table.length > 0;
    }
    findDrop(roll) {
        const drop = this.table.find((item) => item.dropStart <= roll && roll <= item.dropEnd);
        if (!drop) {
            return undefined;
        }
        return drop.item;
    }
}
