import React from 'react';
import { useGameClient } from '../../api/gameClientProvider';
import { GAME_EVENTS } from '../../api/gameEvents';
import ICON_ASSETS from '../../assets/images/icons';
import Button from '../../components/button';
import Card from '../../components/card';
import { useGameState } from '../../components/gameStateProvider';
import ProgressBar from '../../components/progressBar';
import i18n from '../../i18n/i18n';
const ZoneProgressSegment = ({ event, index, zoneProgress, }) => {
    if (index === zoneProgress.currentEventId) {
        return (React.createElement(React.Fragment, null,
            React.createElement("img", { className: "w-8 mx-1", src: ICON_ASSETS[event.type], alt: "" }),
            React.createElement(ProgressBar, { className: "flex-grow bg-gray-300", color: "bg-blue-500", current: zoneProgress.currentProgress, max: 100 })));
    }
    if (index < zoneProgress.currentEventId) {
        return (React.createElement(React.Fragment, null,
            React.createElement("img", { className: "w-8 mx-1", src: ICON_ASSETS[event.type], alt: "" }),
            React.createElement(ProgressBar, { className: "flex-grow bg-gray-300", color: "bg-blue-500", current: 1, max: 1 })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("img", { className: "w-8 mx-1", src: ICON_ASSETS[event.type], alt: "" }),
        React.createElement(ProgressBar, { className: "flex-grow bg-gray-300", color: "bg-blue-500", current: 0, max: 1 })));
};
const ZoneProgress = () => {
    const gameState = useGameState();
    const gameClient = useGameClient();
    const zoneEntity = gameState.world.zone;
    const { zoneProgress } = zoneEntity.components;
    if (!zoneEntity) {
        return React.createElement(Card, { className: "flex-col h-10" });
    }
    return (React.createElement(Card, { className: "flex-col space-y-3" },
        React.createElement("div", { className: "flex flex-row justify-between items-center" },
            React.createElement("div", { className: "text-xl font-medium text-black dark:text-white" }, i18n.get('zone', zoneProgress.zoneId)),
            React.createElement(Button, { className: "w-24", danger: true, onClick: () => gameClient.sendServerEvent(GAME_EVENTS.LEAVE_ZONE) }, "Run")),
        React.createElement("div", { className: "w-full flex flex-row h-10 items-center" }, zoneProgress.events.map((event, index) => (React.createElement(ZoneProgressSegment, { event: event, index: index, zoneProgress: zoneProgress }))))));
};
export default ZoneProgress;
