/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-var-requires */
const imp = require('./imp.png');
const zombie = require('./zombie.png');
const goobin = require('./goobin.png');
const goobin_berserker = require('./goobin_berserker.png');
const MOB_ASSETS = {
    imp,
    zombie,
    goobin,
    goobin_berserker,
};
export default MOB_ASSETS;
