export default [
    {
        id: 'zombie',
        name: 'Zombie',
        stats: {
            life: [7, 12],
            maxLife: [7, 12],
            attackSpeed: [1000],
            minPhysicalDamage: [1],
            maxPhysicalDamage: [1],
            armour: [5],
            lifeCrystals: [0, 1],
            iLevel: [1],
        },
    },
    {
        id: 'imp',
        name: 'Imp',
        stats: {
            life: [1, 5],
            maxLife: [1, 5],
            attackSpeed: [1000],
            minPhysicalDamage: [1],
            maxPhysicalDamage: [2],
            armour: [5],
            lifeCrystals: [0, 1],
            iLevel: [1],
        },
    },
    {
        id: 'goobin',
        name: 'Goobin',
        stats: {
            life: [1, 4],
            maxLife: [1, 4],
            attackSpeed: [1000],
            minPhysicalDamage: [1],
            maxPhysicalDamage: [2],
            armour: [5],
            lifeCrystals: [0, 1],
            iLevel: [1],
        },
    },
    {
        id: 'goobin_berserker',
        name: 'Goobin Berserker',
        stats: {
            life: [150],
            maxLife: [150],
            armour: [10],
            attackSpeed: [800],
            minPhysicalDamage: [15],
            maxPhysicalDamage: [25],
            lifeCrystals: [1, 3],
            iLevel: [13],
        },
    },
];
