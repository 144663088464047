import React from 'react';
import Card from '../../components/card';
import { useGameState } from '../../components/gameStateProvider';
import i18n from '../../i18n/i18n';
import DeathModal from './deathModal';
import ZoneSelect from './zoneSelect';
const Town = () => {
    const gameState = useGameState();
    const { player } = gameState;
    const { progress, dead } = player.components;
    return (React.createElement(React.Fragment, null,
        dead && React.createElement(DeathModal, null),
        React.createElement("div", { className: "flex flex-col w-full space-y-6 p-6" },
            React.createElement(Card, { className: "flex-col space-y-3" },
                React.createElement("div", { className: "flex flex-row justify-between items-center" },
                    React.createElement("div", { className: "text-xl font-medium text-black dark:text-white" }, i18n.get('town.initial')))),
            React.createElement("div", { className: "flex flex-col sm:flex-row w-full sm:space-y-0 justify-between" },
                React.createElement("div", { className: "w-full sm:w-1/3 order-1 mb-6 sm:mb-0 sm:order-2" },
                    React.createElement(ZoneSelect, { zones: progress.zones }))))));
};
export default Town;
