import Component from '../ecs/component';
// Is this needed?
export default class InventorySlotComponent extends Component {
    constructor() {
        super(...arguments);
        this.$id = 'inventorySlot';
        /*
        public slot: number;
        public x: number;
        public y: number;
        */
    }
}
InventorySlotComponent.id = 'inventorySlot';
