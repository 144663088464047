import LootTable from '../utils/lootTable';
export default class ModifierFactory {
    static loadModifiers(modifiers) {
        modifiers.forEach((modifier) => {
            if (!this.MOD_POOLS[modifier.type]) {
                this.MOD_POOLS[modifier.type] = [];
            }
            this.MODS[modifier.id] = modifier;
            this.MOD_POOLS[modifier.type].push(modifier.id);
        });
    }
    static findRandomModifier(random, type, modable) {
        const searchPool = this.MOD_POOLS[type];
        if (!searchPool) {
            return null;
        }
        // Identify a list of possible modifiers
        const lootTable = new LootTable(random, []);
        searchPool.forEach((modifierId) => {
            const mod = this.MODS[modifierId];
            if (!mod) {
                return;
            }
            if (mod.level > modable.level) {
                return;
            }
            if (mod.class !== modable.itemClass) {
                return;
            }
            if (modable.tags[mod.group]) {
                return;
            }
            // Check itemLevel
            // If modable.itemLevel < mod.level; then stop
            const generationWeight = mod.generations.find((chance) => modable.tags[chance.tag]);
            if (!generationWeight || generationWeight.weight <= 0) {
                return;
            }
            const spawnWeight = mod.spawns.find((chance) => modable.tags[chance.tag]);
            if (!spawnWeight || spawnWeight.weight <= 0) {
                return;
            }
            const finalWeight = generationWeight.weight * spawnWeight.weight;
            lootTable.addDrop({
                weight: finalWeight,
                modifierId,
            });
        });
        const { modifierId } = lootTable.roll() || {};
        if (!modifierId) {
            return null;
        }
        const modifier = this.MODS[modifierId];
        return modifier;
    }
}
ModifierFactory.MODS = {};
ModifierFactory.MOD_POOLS = {};
