import * as React from 'react';
import { useEffect, useMemo, useRef, useState, } from 'react';
const CursorContext = React.createContext(null);
const CursorProvider = ({ children, gameState, }) => {
    const [heldEntity, setHeldEntity] = useState();
    const { player } = gameState.player.components;
    const currentlyHeldEntity = heldEntity || gameState.world[player.heldEntityId];
    const context = [currentlyHeldEntity, setHeldEntity];
    return (React.createElement(CursorContext.Provider, { value: context }, children));
};
function useCursorState(specArgs, deps) {
    const options = useMemo(specArgs, deps);
    const [heldEntity, setHeldItem] = React.useContext(CursorContext);
    const itemRef = useRef({});
    useEffect(() => {
        const unregister = () => {
            if (!itemRef.current) {
                return;
            }
            itemRef.current.onclick = null;
            itemRef.current.onmousedown = null;
        };
        if (heldEntity) {
            return unregister;
        }
        if (!itemRef.current) {
            return unregister;
        }
        itemRef.current.onclick = (event) => {
            setHeldItem(options.item);
            options.onSelect(options.item);
        };
        itemRef.current.onmousedown = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setHeldItem(options.item);
            options.onSelect(options.item);
        };
        return unregister;
    }, [itemRef.current, heldEntity, options]);
    const state = {
        heldEntity,
        isSelected: heldEntity && options.item && options.item.id === heldEntity.id,
    };
    return [state, itemRef];
}
function useCursorDropState(specArgs, deps) {
    const options = useMemo(specArgs, deps);
    const [heldEntity, setHeldItem] = React.useContext(CursorContext);
    const [isOver, setOver] = useState(false);
    const [canDrop, setCanDrop] = useState(false);
    const dropRef = useRef({});
    useEffect(() => {
        const unregister = () => {
            if (!dropRef.current) {
                return;
            }
            dropRef.current.onmouseover = null;
            dropRef.current.onmouseleave = null;
            dropRef.current.onclick = null;
        };
        if (!heldEntity) {
            return unregister;
        }
        if (!dropRef.current) {
            return unregister;
        }
        dropRef.current.onmouseover = (event) => {
            setOver(true);
            setCanDrop(options.canDrop(heldEntity));
        };
        dropRef.current.onmouseleave = (event) => {
            setOver(false);
            setCanDrop(false);
        };
        dropRef.current.onclick = (event) => {
            if (!options.canDrop(heldEntity)) {
                return;
            }
            setOver(false);
            setCanDrop(false);
            setHeldItem(options.drop(heldEntity));
        };
        return unregister;
    }, [dropRef.current, heldEntity, options, setOver, setCanDrop]);
    const state = {
        heldEntity,
        isOver,
        canDrop,
    };
    return [state, dropRef];
}
export { CursorProvider, useCursorState, useCursorDropState };
