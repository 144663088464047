import DeadComponent from '../components/deadComponent';
import System from '../ecs/system';
export default class PlayerSystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.requiredComponentIds = ['player'];
        this.onPlayerKilled = this.onPlayerKilled.bind(this);
        this.onPlayerRespawn = this.onPlayerRespawn.bind(this);
        this.eventBus.addListener('PLAYER_KILLED', this.onPlayerKilled);
        this.eventBus.addListener('PLAYER_RESPAWN', this.onPlayerRespawn);
    }
    update(gameTime) {
        super.update(gameTime);
        const monsters = this.entityManager.getEntitiesWith(['mob', 'stats']);
        this.entityIds.forEach((entityId) => {
            const entity = this.entityManager.getEntity(entityId);
            const target = entity.get('target');
            const stats = entity.get('stats');
            // Set the target
            if (!target.targetId) {
                if (monsters[0]) {
                    target.targetId = monsters[0].id;
                }
            }
            // If the player life is less than 30% of their max, use a life crystal
            if (stats.get('life') <= 0.3 * stats.get('maxLife')) {
                this.eventBus.fire('USE_LIFE_CRYSTAL', { entityId });
            }
        });
    }
    onAddEntity(entity) {
        if (!super.onAddEntity(entity)) {
            return false;
        }
        this.eventBus.fire('UPDATE_STATS', { entityId: entity.id });
        return true;
    }
    onPlayerKilled({ entityId }) {
        this.entityManager.addComponent(entityId, new DeadComponent());
        this.eventBus.fire('LEAVE_ZONE', {});
    }
    onPlayerRespawn({ entityId }) {
        this.entityManager.removeComponent(entityId, 'dead');
    }
}
