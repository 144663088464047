import Component from '../ecs/component';
// TODO: list all possible UI progress events...
export default class ZoneProgressComponent extends Component {
    constructor({ zoneId, currentEventId, events, }) {
        super();
        this.$id = 'zoneProgress';
        this.zoneId = zoneId;
        this.currentEventId = currentEventId || 0;
        this.currentProgress = 0;
        this.events = events || [];
    }
}
ZoneProgressComponent.id = 'zoneProgress';
