import System from '../ecs/system';
import StatFactory from '../factories/statFactory';
export default class StatsSystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.requiredComponentIds = [];
        this.onItemChanged = this.onItemChanged.bind(this);
        this.eventBus.addListener('UPDATE_STATS', this.onItemChanged);
        this.eventBus.addListener('ITEM_EQUIPPED', this.onItemChanged);
        this.eventBus.addListener('ITEM_UNEQUIPPED', this.onItemChanged);
        this.onPlayerKilled = this.onPlayerKilled.bind(this);
        this.eventBus.addListener('PLAYER_KILLED', this.onPlayerKilled);
    }
    onItemChanged({ entityId }) {
        // On item change, recalculate ALL stats...
        // Get the entity that changed...
        const entity = this.entityManager.getEntity(entityId);
        const modifiers = this.getEquipmentModifiers(entity);
        // Buffs can be modifiers applied to an entity, or zone
        // entity.get('buffs')
        StatsSystem.updateStats(entity, modifiers);
    }
    getEquipmentModifiers(entity) {
        const modifiers = [];
        // Get all their equipment...
        const equipment = entity.get('equipment');
        equipment.items.forEach((itemId) => {
            const itemEntity = this.entityManager.getEntity(itemId);
            // getItemComponent....
            const itemStats = itemEntity.get('stats');
            const item = itemEntity.get('item');
            // Recalculate
            StatFactory.applyModifiers(itemStats, [
                StatFactory.getLocalModifier(item.modifiers),
            ]);
            modifiers.push(StatFactory.convertCurrentStatsToModifier(itemStats));
            modifiers.push(StatFactory.getGlobalModifier(item.modifiers));
        });
        return modifiers;
    }
    static updateStats(entity, modifiers) {
        const stats = entity.get('stats');
        StatFactory.applyModifiers(stats, modifiers);
        return stats;
    }
    onPlayerKilled({ entityId }) {
        // Reset the player stats...
        const entity = this.entityManager.getEntity(entityId);
        const modifiers = this.getEquipmentModifiers(entity);
        const stats = StatsSystem.updateStats(entity, modifiers);
        stats.set('life', stats.get('maxLife'));
    }
}
