export default [
    {
        id: 'local_physical_damage_+%',
        modifier: 'increase',
        alias: ['minPhysicalDamage', 'maxPhysicalDamage'],
        isLocal: true,
    },
    {
        id: 'minPhysicalDamage',
        modifier: 'add',
        alias: [],
    },
    {
        id: 'maxPhysicalDamage',
        modifier: 'add',
        alias: [],
    },
    {
        id: 'local_attack_speed_+%',
        modifier: 'increase',
        alias: ['attackSpeed'],
        isLocal: true,
    },
    {
        id: 'attackSpeed',
        modifier: 'increase',
        alias: [],
    },
    {
        id: 'local_armour_+%',
        modifier: 'increase',
        alias: ['armour'],
        isLocal: true,
    },
    {
        id: 'armour',
        modifier: 'add',
        alias: [],
    },
    {
        id: 'movementSpeed',
        modifier: 'increase',
        alias: [],
    },
    {
        id: 'maxLife',
        modifier: 'add',
        alias: [],
    },
];
