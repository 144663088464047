/* eslint-disable no-param-reassign */
function calculateModifiers(stats, modifiers, statId) {
    let total = stats.base[statId] || 0;
    let increase = 1;
    let multiplier = 1;
    modifiers.forEach((modifier) => {
        if (modifier.add) {
            total += modifier.add[statId] || 0;
        }
        if (modifier.increase) {
            increase += (modifier.increase[statId] || 0) / 100;
        }
        if (modifier.multiply) {
            multiplier += (modifier.multiply[statId] || 0) / 100;
        }
    });
    total = total * increase * multiplier;
    total = Math.ceil(total);
    return total;
}
export default function statBasic(stats, modifiers, statId) {
    stats.current[statId] = calculateModifiers(stats, modifiers, statId);
    // Remove zero value stats...
    if (stats.current[statId] === 0) {
        delete stats.current[statId];
    }
}
