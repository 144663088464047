import React from 'react';
import { useGameClient } from '../../../api/gameClientProvider';
import { GAME_EVENTS } from '../../../api/gameEvents';
import Button from '../../../components/button';
import Card from '../../../components/card';
import { useGameState } from '../../../components/gameStateProvider';
import i18n from '../../../i18n/i18n';
const ZoneSelect = () => {
    const gameState = useGameState();
    const gameClient = useGameClient();
    const zoneEntity = gameState.world.zone;
    const { zoneProgress } = zoneEntity.components;
    const currentEvent = zoneProgress.events[zoneProgress.currentEventId];
    const isPathway = zoneProgress.currentEventId < zoneProgress.events.length - 1;
    const loadZoneCallback = (zoneId) => () => gameClient.sendServerEvent(GAME_EVENTS.LOAD_ZONE, { zoneId });
    return (React.createElement(Card, { className: "flex-col h-full" },
        isPathway && (React.createElement(Button, { positive: true, className: "w-full", onClick: () => {
                gameClient.sendServerEvent('CONTINUE_ZONE', {});
            } }, "Continue through zone")),
        currentEvent.zoneIds.map((zoneId) => (React.createElement(Button, { key: zoneId, primary: true, className: "w-full mt-2", onClick: loadZoneCallback(zoneId) },
            "Go to ",
            i18n.get('zone', zoneId))))));
};
export default ZoneSelect;
