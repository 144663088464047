import clsx from 'clsx';
import React, { useCallback } from 'react';
import ITEM_ASSETS from '../../assets/images/items';
import ItemStats from './itemStats';
const Item = ({ itemRef, entity, onClick, className, style, }) => {
    const { item } = entity.components;
    const tooltipId = `stats-${entity.id}`;
    const onClickCallback = useCallback((event) => {
        if (onClick) {
            onClick(entity);
        }
    }, [entity]);
    return (React.createElement("div", { ref: itemRef, "data-tip": true, "data-for": tooltipId, className: clsx('border-2 rounded-md', {
            'bg-gray-300 border-gray-500': item.rarity === 'normal',
            'bg-blue-300 border-blue-500': item.rarity === 'magic',
            'bg-yellow-300 border-yellow-500': item.rarity === 'rare',
            'bg-red-300 border-red-500': item.rarity === 'legendary',
        }, className), style: style, onClick: onClickCallback },
        React.createElement("img", { className: "w-full pointer-events-none select-none", src: ITEM_ASSETS[item.id], alt: item.name }),
        React.createElement(ItemStats, { tooltipId: tooltipId, entity: entity })));
};
export default Item;
