import { deserializeComponent } from '../components';
import genId from '../utils/genId';
// TODO: does an entity have a fixture on the message system?
export default class Entity {
    constructor({ id, components, } = {}) {
        this.id = id || genId();
        this.components = [...(components || [])];
    }
    getComponentById(componentId) {
        return this.components.find((component) => component.$id === componentId);
    }
    addComponent(component) {
        this.components.push(component);
    }
    // TODO: is this called?
    removeComponent(componentId) {
        const componentIndex = this.components.findIndex((component) => component.$id === componentId);
        if (componentIndex === -1) {
            return;
        }
        this.components.splice(componentIndex, 1);
    }
    // Shorthand
    get(componentId) {
        const component = this.components.find((item) => item.$id === componentId);
        return component;
    }
    // TODO: serialize
    // TODO: instantiate
    serialize(entityManager, output) {
        // walk through ALL the components and serialize each one, IF there are any entities, add them to the entities array...
        // Should components be serialized by type? E.g. as a dictionary, this would improve json patching and usability...
        const data = {
            id: this.id,
            components: {},
        };
        this.components.forEach((component) => {
            data.components[component.$id] = component.serialize(entityManager, output);
        });
        // eslint-disable-next-line no-param-reassign
        output[data.id] = data;
    }
    static deserialize(data) {
        const components = Object.values(data.components).map((component) => {
            return deserializeComponent(component);
        });
        const entity = new Entity({
            id: data.id,
            components,
        });
        return entity;
    }
}
