export default [
    {
        stats: ['minPhysicalDamage', 'maxPhysicalDamage'],
        conditions: [],
        template: 'Physical Damage: {{#0}} to {{#1}}',
        altTemplate: 'Physical Damage: blue::({{#0}} to {{#1}})',
    },
    {
        stats: ['armour'],
        conditions: [],
        template: 'Armour: {{#0}}',
        altTemplate: 'Armour: blue::({{#0}})',
    },
    {
        stats: ['attackSpeed'],
        conditions: [],
        transforms: [
            {
                type: 'numerator',
                value: 1000,
            },
        ],
        template: 'Attacks per Second: {{#0}}',
        altTemplate: 'Attacks per Second: blue::({{#0}})',
    },
];
