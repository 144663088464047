// eslint-disable-next-line import/prefer-default-export
import Component from '../ecs/component';
import CharacterComponent from './characterComponent';
import DeadComponent from './deadComponent';
import EquipableComponent from './equipableComponent';
import EquipmentComponent from './equipmentComponent';
import InventoryComponent from './inventoryComponent';
import InventorySlotComponent from './inventorySlotComponent';
import ItemComponent from './itemComponent';
import LootTableComponent from './lootTableComponent';
import MobComponent from './mobComponent';
import PlayerComponent from './playerComponent';
import ProgressComponent from './progressComponent';
import StatsComponent from './statsComponent';
import TargetComponent from './targetComponent';
import ZoneComponent from './zoneComponent';
import ZoneProgressComponent from './zoneProgressComponent';
export default {
    CharacterComponent,
    EquipableComponent,
    EquipmentComponent,
    InventoryComponent,
    InventorySlotComponent,
    ItemComponent,
    LootTableComponent,
    MobComponent,
    PlayerComponent,
    ProgressComponent,
    StatsComponent,
    TargetComponent,
    ZoneComponent,
    ZoneProgressComponent,
};
const COMPONENT_LOOKUP = {
    $default: Component,
    [CharacterComponent.id]: CharacterComponent,
    [DeadComponent.id]: DeadComponent,
    [EquipableComponent.id]: EquipableComponent,
    [EquipmentComponent.id]: EquipmentComponent,
    [InventoryComponent.id]: InventoryComponent,
    [InventorySlotComponent.id]: InventorySlotComponent,
    [ItemComponent.id]: ItemComponent,
    [LootTableComponent.id]: LootTableComponent,
    [MobComponent.id]: MobComponent,
    [PlayerComponent.id]: PlayerComponent,
    [ProgressComponent.id]: ProgressComponent,
    [StatsComponent.id]: StatsComponent,
    [TargetComponent.id]: TargetComponent,
    [ZoneComponent.id]: ZoneComponent,
    [ZoneProgressComponent.id]: ZoneProgressComponent,
};
export function deserializeComponent(componentData) {
    const ComponentClass = COMPONENT_LOOKUP[componentData.$id] || COMPONENT_LOOKUP.$default;
    return new ComponentClass(componentData);
}
