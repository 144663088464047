const dictionary = {
    zone: {
        the_meadow: 'Goobin Meadow',
        the_cave: 'Zerker Cave',
    },
    mob: {
        imp: 'Imp',
        zombie: 'Zombie',
        goobin: 'Goobin',
        goobin_berserker: 'Goobin Berserker',
    },
    town: {
        initial: "Fortuna's Garden",
    },
    ui: {
        death: {
            messageLine1: 'Luck is on your side - Fortuna smiles upon you.',
            messageLine2: "You awaken in Fortuna's garden without a scratch.",
        },
    },
};
export default dictionary;
