import clsx from 'clsx';
import React from 'react';
const ProgressSphere = ({ className, color, current, max, children, }) => {
    let progress = Number(((current / max) * 100).toFixed(2));
    progress = Math.max(progress, 0);
    progress = 100 - progress;
    return (React.createElement("div", { className: clsx('overflow-hidden rounded-full flex', className) },
        React.createElement("div", { style: { marginTop: `${progress}%` }, className: clsx('transition-all duration-300 shadow-none flex w-full', color) }, children)));
};
export default ProgressSphere;
