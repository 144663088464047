export default [
    {
        id: 'local_physical_damage_+%_t1',
        name: '',
        type: 'prefix',
        class: 'item',
        group: 'local_physical_damage',
        level: 1,
        generations: [
            {
                tag: 'attack_mod',
                weight: 100,
            },
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'weapon',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'local_physical_damage_+%',
                min: 10,
                max: 20,
            },
        ],
        addTags: ['attack_mod'],
    },
    {
        id: 'local_physical_damage_+%_t2',
        name: '',
        type: 'prefix',
        class: 'item',
        group: 'local_physical_damage',
        level: 10,
        generations: [
            {
                tag: 'attack_mod',
                weight: 100,
            },
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'weapon',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'local_physical_damage_+%',
                min: 21,
                max: 30,
            },
        ],
        addTags: ['attack_mod'],
    },
    {
        id: 'local_attack_speed_+%_t1',
        name: '',
        type: 'suffix',
        class: 'item',
        group: 'local_attack_speed',
        level: 1,
        generations: [
            {
                tag: 'attack_mod',
                weight: 100,
            },
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'weapon',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'local_attack_speed_+%',
                min: -10,
            },
        ],
        addTags: ['attack_mod'],
    },
    {
        id: 'local_attack_speed_+%_t2',
        name: '',
        type: 'suffix',
        class: 'item',
        group: 'local_attack_speed',
        level: 10,
        generations: [
            {
                tag: 'attack_mod',
                weight: 100,
            },
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'weapon',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'local_attack_speed_+%',
                min: -20,
            },
        ],
        addTags: ['attack_mod'],
    },
    {
        id: 'movement_speed_+%_t1',
        name: '',
        type: 'suffix',
        class: 'item',
        group: 'global_movement_speed',
        level: 1,
        generations: [
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'boots',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'movementSpeed',
                min: 10,
            },
        ],
        addTags: [],
    },
    {
        id: 'movement_speed_+%_t2',
        name: '',
        type: 'suffix',
        class: 'item',
        group: 'global_movement_speed',
        level: 10,
        generations: [
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'boots',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'movementSpeed',
                min: 20,
            },
        ],
        addTags: [],
    },
    {
        id: 'added_physical_damage_t1',
        name: '',
        type: 'suffix',
        class: 'item',
        group: 'global_physical_damage',
        level: 1,
        generations: [
            {
                tag: 'attack_mod',
                weight: 100,
            },
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'weapon',
                weight: 1000,
            },
            {
                tag: 'armour',
                weight: 500,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'minPhysicalDamage',
                min: 1,
                max: 3,
            },
            {
                stat: 'maxPhysicalDamage',
                min: 2,
                max: 8,
            },
        ],
        addTags: ['attack_mod'],
    },
    {
        id: 'added_physical_damage_t2',
        name: '',
        type: 'suffix',
        class: 'item',
        group: 'global_physical_damage',
        level: 10,
        generations: [
            {
                tag: 'attack_mod',
                weight: 100,
            },
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'weapon',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'minPhysicalDamage',
                min: 3,
                max: 5,
            },
            {
                stat: 'maxPhysicalDamage',
                min: 3,
                max: 12,
            },
        ],
        addTags: ['attack_mod'],
    },
    {
        id: 'local_armour_+%_t1',
        name: '',
        type: 'prefix',
        class: 'item',
        group: 'local_armour',
        level: 1,
        generations: [
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'armour',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'local_armour_+%',
                min: 10,
                max: 30,
            },
        ],
        addTags: [],
    },
    {
        id: 'local_armour_+%_t2',
        name: '',
        type: 'prefix',
        class: 'item',
        group: 'local_armour',
        level: 10,
        generations: [
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'armour',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'local_armour_+%',
                min: 31,
                max: 40,
            },
        ],
        addTags: [],
    },
    {
        id: 'add_life_t1',
        name: '',
        type: 'suffix',
        class: 'item',
        group: 'add_life',
        level: 1,
        generations: [
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'armour',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'maxLife',
                min: 1,
                max: 5,
            },
        ],
        addTags: [],
    },
    {
        id: 'add_life_t2',
        name: '',
        type: 'suffix',
        class: 'item',
        group: 'add_life',
        level: 10,
        generations: [
            {
                tag: 'default',
                weight: 100,
            },
        ],
        spawns: [
            {
                tag: 'armour',
                weight: 1000,
            },
            {
                tag: 'default',
                weight: 0,
            },
        ],
        stats: [
            {
                stat: 'maxLife',
                min: 6,
                max: 10,
            },
        ],
        addTags: [],
    },
];
