import InventorySlotComponent from '../components/inventorySlotComponent';
import ZoneComponent from '../components/zoneComponent';
import System from '../ecs/system';
export default class InventorySystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.requiredComponentIds = [];
        this.onPickupItem = this.onPickupItem.bind(this);
        this.onDropItem = this.onDropItem.bind(this);
        this.eventBus.addListener('PICKUP_ITEM', this.onPickupItem);
        this.eventBus.addListener('DROP_ITEM', this.onDropItem);
    }
    /*
     * Upon picking up a new item, we must place our existing item somewhere
     */
    onPickupItem({ entityId, itemId, equipmentSlot, }) {
        // Check for an item...
        const item = this.entityManager.getEntity(itemId);
        if (!item) {
            return;
        }
        const isItem = item.get('item');
        if (!isItem) {
            return;
        }
        // Get the target entity
        const entity = this.entityManager.getEntity(entityId);
        const player = entity.get('player');
        const inventory = entity.get('inventory');
        const equipment = entity.get('equipment');
        if (!player) {
            return;
        }
        const currentlyHeldItemId = player.heldEntityId;
        const currentlyHeldItem = this.entityManager.getEntity(currentlyHeldItemId);
        // Check if the item was picked up from the floor
        const isFloor = item.get('zone');
        if (isFloor) {
            player.heldEntityId = item.id;
            // Remove the zone component from the newly picked up item
            this.entityManager.removeComponent(item.id, isFloor.$id);
            // Add the zone component to the freshly dropped item
            this.entityManager.addComponent(currentlyHeldItemId, new ZoneComponent());
            return;
        }
        // Check if the item was picked up from the inventory
        const inventoryIndex = inventory.items.indexOf(item.id);
        if (inventoryIndex >= 0) {
            player.heldEntityId = item.id;
            inventory.items[inventoryIndex] = currentlyHeldItemId;
            // Remove the inventory component from the newly picked up item
            this.entityManager.removeComponent(item.id, InventorySlotComponent.id);
            // Add the inventory component to the freshly dropped item
            this.entityManager.addComponent(currentlyHeldItemId, new InventorySlotComponent());
            return;
        }
        // Finally check if the item was picked up from our equipment
        if (equipment.items.includes(item.id)) {
            if (currentlyHeldItem) {
                // check if the item was inside the "equipmentSlot"
                if (!equipmentSlot) {
                    return;
                }
                // If the picked up item IS not in the slot specified, then stop
                if (equipment.slots[equipmentSlot] !== item.id) {
                    return;
                }
                // 1. Unequip picked up item
                // 2. Try equip held item
                // 3. IF held item is NOT equipped, reequip picked up item
                this.eventBus.fire('UNEQUIP_ITEM', {
                    entityId,
                    itemId,
                });
                // Try equip to the slot the item was picked up from
                this.eventBus.fire('TRY_EQUIP_ITEM', {
                    entityId,
                    itemId: currentlyHeldItemId,
                    slot: equipmentSlot,
                });
                // If we did not equip the item, then rollback
                if (!equipment.items.includes(currentlyHeldItemId)) {
                    player.heldEntityId = currentlyHeldItemId;
                    this.eventBus.fire('EQUIP_ITEM', {
                        entityId,
                        itemId,
                        slot: equipmentSlot,
                    });
                }
                else {
                    player.heldEntityId = item.id;
                }
            }
            else {
                player.heldEntityId = item.id;
                this.eventBus.fire('UNEQUIP_ITEM', {
                    entityId,
                    itemId,
                });
            }
        }
    }
    onDropItem({ entityId, inventorySlot, }) {
        // Get the player entity
        const entity = this.entityManager.getEntity(entityId);
        const player = entity.get('player');
        const inventory = entity.get('inventory');
        if (!player) {
            return;
        }
        if (!player.heldEntityId) {
            return;
        }
        // Check for an item...
        const item = this.entityManager.getEntity(player.heldEntityId);
        if (!item) {
            return;
        }
        const isItem = item.get('item');
        if (!isItem) {
            return;
        }
        // If we are not targeting an inventory slot, drop the item to the floor
        if (inventorySlot === undefined) {
            this.entityManager.addComponent(player.heldEntityId, new ZoneComponent());
            player.heldEntityId = undefined;
            return;
        }
        // If the slot is already occupied, pick up the item
        if (inventory.items[inventorySlot]) {
            this.eventBus.fire('PICKUP_ITEM', {
                entityId,
                itemId: inventory.items[inventorySlot],
            });
            return;
        }
        // If we are dropping in a slot that does not exist, then stop
        if (inventorySlot > inventory.items.length - 1) {
            return;
        }
        this.entityManager.addComponent(player.heldEntityId, new InventorySlotComponent());
        inventory.items[inventorySlot] = player.heldEntityId;
        player.heldEntityId = undefined;
    }
}
