import { Room } from '@local/infra';
export default class GameClient {
    createCharacter() { }
    listCharacters() { }
    join(characterId = 'playerId-1') {
        // First leave any rooms we might be in.
        this.leave();
        this.characterId = characterId;
        this.room = new Room();
        this.room.join({ characterId });
    }
    leave() {
        if (!this.room) {
            return;
        }
        if (!this.characterId) {
            return;
        }
        this.room.sendMessageToClient = () => { };
        this.room.leave({ characterId: this.characterId });
    }
    addServerEventListener(cb) {
        if (!this.room) {
            return;
        }
        this.room.sendMessageToClient = cb;
    }
    sendServerEvent(eventId, eventValue = {}) {
        if (!this.room) {
            return;
        }
        console.log('#input_event', eventId, eventValue);
        this.room.sendMessageToServer(this.characterId, eventId, eventValue);
    }
}
