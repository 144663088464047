import React, { useMemo } from 'react';
import { useGameClient } from '../../api/gameClientProvider';
import { GAME_EVENTS } from '../../api/gameEvents';
import Card from '../../components/card';
import { useCursorDropState } from '../../components/cursorProvider';
import { useGameState } from '../../components/gameStateProvider';
import ItemClickable from '../ui/itemClickable';
import EntityManager from '../utils/entityManager';
const ZoneGround = () => {
    const gameState = useGameState();
    const gameClient = useGameClient();
    const [, drop] = useCursorDropState(() => ({
        canDrop: (item) => true,
        drop: (item) => {
            gameClient.sendServerEvent(GAME_EVENTS.DROP_ITEM);
            return null;
        },
    }), []);
    const items = useMemo(() => EntityManager.getEntitiesWith(gameState.entities, ['zone', 'item']), [gameState.entities]);
    return (React.createElement(Card, { className: "flex-col", innerRef: drop },
        React.createElement("div", { className: "w-full text-black dark:text-white text-lg font-medium mb-0.5" }, "Drops"),
        React.createElement("div", { className: "w-full" }, items.map((item) => (React.createElement(ItemClickable, { key: item.id, className: "inline-block m-1 w-16 h-16", entity: item }))))));
};
export default ZoneGround;
