import clsx from 'clsx';
import React from 'react';
import { useGameClient } from '../../../api/gameClientProvider';
import { GAME_EVENTS } from '../../../api/gameEvents';
import { useCursorDropState } from '../../../components/cursorProvider';
const EquipmentSlot = ({ children, className, slot, occupied, }) => {
    // TODO: handle slot dropping...
    const gameClient = useGameClient();
    const [{ heldEntity }, drop] = useCursorDropState(() => ({
        canDrop: (item) => {
            const { equipable } = item.components;
            return equipable.slots.includes(slot);
        },
        drop: (item) => {
            gameClient.sendServerEvent(GAME_EVENTS.EQUIP_ITEM, {
                slot,
            });
            return occupied;
        },
    }), [slot]);
    return (React.createElement("div", { ref: drop, className: clsx(className, 'w-20 h-20 p-1 rounded-md border-inset border-2 border-yellow-300 bg-gray-700') }, children));
};
export default EquipmentSlot;
