import clsx from 'clsx';
import React from 'react';
import { useGameClient } from '../../api/gameClientProvider';
import { GAME_EVENTS } from '../../api/gameEvents';
import ICON_ASSETS from '../../assets/images/icons';
import { useGameState } from '../../components/gameStateProvider';
const LifeCrystal = ({ className, dark }) => {
    const gameClient = useGameClient();
    const { player } = useGameState();
    const { lifeCrystals } = player.components.equipment.misc;
    const onClick = () => {
        gameClient.sendServerEvent(GAME_EVENTS.USE_LIFE_CRYSTAL);
    };
    return (React.createElement("button", { type: "button", className: "relative flex flex-row items-center outline-none focus:outline-none", onClick: onClick },
        React.createElement("img", { className: clsx('h-8 w-8 cursor-pointer', className), src: ICON_ASSETS.lifeCrystal, alt: "Life Crystal" }),
        React.createElement("div", { className: "absolute -bottom-2 -right-1 text-white text-sm font-medium" }, lifeCrystals)));
};
export default LifeCrystal;
