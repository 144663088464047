// todo: rename to EntityManager
export default class EntityManager {
    constructor(eventBus) {
        this.entities = {};
        this.eventBus = eventBus;
        this.onAddEntity = this.onAddEntity.bind(this);
        this.onRemoveEntity = this.onRemoveEntity.bind(this);
        this.onAddComponent = this.onAddComponent.bind(this);
        this.onRemoveComponent = this.onRemoveComponent.bind(this);
        this.eventBus.addListener('ADD_ENTITY', this.onAddEntity);
        this.eventBus.addListener('REMOVE_ENTITY', this.onRemoveEntity);
        this.eventBus.addListener('ADD_COMPONENT', this.onAddComponent);
        this.eventBus.addListener('REMOVE_COMPONENT', this.onRemoveComponent);
    }
    getEntity(entityId) {
        if (!entityId) {
            return undefined;
        }
        return this.entities[entityId];
    }
    getEntitiesWith(filter) {
        const entities = Object.values(this.entities);
        const filteredEntities = entities.filter((entity) => {
            return filter.every((componentId) => entity.get(componentId));
        });
        return filteredEntities;
    }
    onAddEntity(entity) {
        this.entities[entity.id] = entity;
    }
    onRemoveEntity(entity) {
        delete this.entities[entity.id];
    }
    onAddComponent({ entityId, component }) {
        const entity = this.entities[entityId];
        if (!entity) {
            return;
        }
        entity.addComponent(component);
    }
    onRemoveComponent({ entityId, componentId }) {
        const entity = this.entities[entityId];
        if (!entity) {
            return;
        }
        entity.removeComponent(componentId);
    }
    addComponent(entityId, component) {
        if (!entityId) {
            return;
        }
        this.eventBus.fire('ADD_COMPONENT', { entityId, component });
    }
    removeComponent(entityId, componentId) {
        if (!entityId) {
            return;
        }
        this.eventBus.fire('REMOVE_COMPONENT', { entityId, componentId });
    }
}
