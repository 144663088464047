import React from 'react';
import { useGameState } from '../../components/gameStateProvider';
import HeldItem from '../ui/heldItem';
import LifeCrystal from '../ui/lifeCrystal';
import UiSphere from './uiSphere';
const GameToolbar = () => {
    const gameState = useGameState();
    const { player } = gameState;
    const { stats } = player.components;
    const currentLife = Math.round(stats.current.life);
    const currentMana = 100;
    const maxMana = 100;
    const { maxLife } = stats.current;
    // Put the held item in the inventory bar...
    return (React.createElement("div", { className: "fixed bottom-0 left-0 w-full flex justify-center pointer-events-none" },
        React.createElement("div", { className: "flex flex-row w-full sm:w-10/12 md:w-3/4 lg:w-2/3 items-end pb-2" },
            React.createElement("div", { className: "w-40 sm:w-40 md:w-40 relative z-10 -ml-24 sm:m-0 overflow-hidden" },
                React.createElement(UiSphere, { position: "left", current: currentLife, max: maxLife, color: "bg-red-500", backgroundColor: "text-red-300", foregroundColor: "text-red-500" })),
            React.createElement("div", { className: "relative flex flex-row flex-grow justify-between items-center px-4 h-20 bg-gray-800 border-gray-900 z-20 pointer-events-auto" },
                React.createElement("div", { className: "absolute left-0 -top-6 w-full flex flex-row px-2 justify-between" },
                    React.createElement("div", { className: "text-black dark:text-white font-medium" },
                        React.createElement("span", { className: "pr-1" }, "Life:"),
                        React.createElement("span", { className: "w-20 inline-block text-right" },
                            currentLife,
                            " / ",
                            maxLife)),
                    React.createElement("div", { className: "text-black dark:text-white font-medium" },
                        React.createElement("span", { className: "pr-1" }, "Mana:"),
                        React.createElement("span", { className: "w-20 inline-block text-right" },
                            currentMana,
                            " / ",
                            maxMana))),
                React.createElement("div", { className: "p-2 border-inset border-2 border-gray-300 bg-gray-700 w-16 h-16" },
                    React.createElement(LifeCrystal, { className: "w-full h-auto", dark: true })),
                React.createElement("div", { className: "p-1 border-inset border-2 border-gray-300 bg-gray-700 w-16 h-16" },
                    React.createElement(HeldItem, { className: "w-full h-auto" })),
                React.createElement("div", { className: "absolute top-0 left-0 w-1/2 border-b-2 border-gray-900 dark:border-gray-400" }),
                React.createElement("div", { className: "absolute bottom-0 left-0 w-1/2 border-b-2 border-gray-900 dark:border-gray-400" }),
                React.createElement("div", { className: "absolute top-0 right-0 w-1/2 border-b-2 border-gray-900 dark:border-gray-400" }),
                React.createElement("div", { className: "absolute bottom-0 right-0 w-1/2 border-b-2 border-gray-900 dark:border-gray-400" })),
            React.createElement("div", { className: "w-40 sm:w-40 md:w-40 relative z-10 -mr-24 sm:m-0 overflow-hidden" },
                React.createElement(UiSphere, { position: "right", current: currentMana, max: currentMana, color: "bg-blue-500", backgroundColor: "text-blue-300", foregroundColor: "text-blue-500" })))));
};
export default GameToolbar;
