import Component from '../ecs/component';
/*
The equipable component must have a list of slots that it can be placed into, along with a list of slots it blocks.
For example a two handed sword can be placed on the left or right hand and blocks the left and right hands
 */
export default class EquipableComponent extends Component {
    constructor({ slots, blocks }) {
        super();
        this.$id = 'equipable';
        // This is used for things like two handed items or uniques that block other slots etc...
        this.blocks = [];
        this.slots = slots || [];
        this.blocks = blocks || [];
    }
}
EquipableComponent.id = 'equipable';
