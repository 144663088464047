import InventorySlotComponent from '../components/inventorySlotComponent';
import System from '../ecs/system';
// Equipping an item will remove it from the floor or inventory
// It will also move any currently equipped items into the inventory
export default class EquipmentSystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.requiredComponentIds = ['equipment', 'stats'];
        this.onTryEquipItem = this.onTryEquipItem.bind(this);
        this.onEquip = this.onEquip.bind(this);
        this.onUnequip = this.onUnequip.bind(this);
        this.eventBus.addListener('TRY_EQUIP_ITEM', this.onTryEquipItem);
        this.eventBus.addListener('EQUIP_ITEM', this.onEquip);
        this.eventBus.addListener('UNEQUIP_ITEM', this.onUnequip);
    }
    onTryEquipItem({ entityId, slot, }) {
        const playerEntity = this.entityManager.getEntity(entityId);
        if (!playerEntity) {
            return;
        }
        const player = playerEntity.get('player');
        const equipment = playerEntity.get('equipment');
        const inventory = playerEntity.get('inventory');
        const itemIdToEquip = player.heldEntityId;
        const item = this.entityManager.getEntity(itemIdToEquip);
        if (!item) {
            return;
        }
        // If the item is NOT equipable, then stop
        const equipable = item.get('equipable');
        if (!equipable) {
            return;
        }
        // If the item is NOT equipable to our slot, then stop
        if (!equipable.slots.includes(slot)) {
            return;
        }
        // Check if slot is already equipped, if so, fire a pickup item event...
        if (equipment.slots[slot]) {
            if (equipment.slots[slot] === itemIdToEquip) {
                return;
            }
            this.eventBus.fire('PICKUP_ITEM', {
                entityId,
                itemId: equipment.slots[slot],
                equipmentSlot: slot,
            });
            return;
        }
        // TODO: check stats...
        // Identify how many spaces the inventory has
        // If we are going to unequip more items than space available, stop
        const itemsToUnequip = [];
        const slotsBlockedByItem = [slot, ...equipable.blocks];
        // Track our current slots before equipping a new item
        // Identify how many items must be unequipped...
        slotsBlockedByItem.forEach((blockedSlot) => {
            const slotItemId = equipment.slots[blockedSlot];
            if (!slotItemId) {
                return;
            }
            itemsToUnequip.push(slotItemId);
        });
        // Check if we have space in the inventory for the items
        if (inventory.used() + itemsToUnequip.length > inventory.size()) {
            return;
        }
        // Unequip the blocked items
        itemsToUnequip.forEach((unequipItemId) => {
            this.eventBus.fire('UNEQUIP_ITEM', {
                entityId,
                itemId: unequipItemId,
            });
            const nextEmptyIndex = inventory.items.findIndex((i) => !i);
            if (nextEmptyIndex >= 0) {
                inventory.items[nextEmptyIndex] = unequipItemId;
            }
            else {
                // TODO: this should never happen because we have checked the i
                inventory.items.push(unequipItemId);
            }
            this.entityManager.addComponent(unequipItemId, new InventorySlotComponent());
        });
        // Finally equip the item
        player.heldEntityId = undefined;
        this.eventBus.fire('EQUIP_ITEM', {
            entityId,
            itemId: itemIdToEquip,
            slot,
        });
    }
    // TODO: handle requirement based equipping...
    // That can be another system that checks item requirements by mocking stats...
    // TODO: make remove from event bus?
    // TODO: this is used to "force equip" an item, it does NOT check stats or availability...
    // Use with caution
    onEquip({ entityId, itemId, slot }) {
        // If this entity does NOT have an equipment component
        if (!this.entityIds.includes(entityId)) {
            return;
        }
        // Check for an item...
        const item = this.entityManager.getEntity(itemId);
        const equipable = item.get('equipable');
        if (!equipable) {
            return;
        }
        if (!equipable.slots.includes(slot)) {
            return;
        }
        // Check for an entity
        const entity = this.entityManager.getEntity(entityId);
        const equipment = entity.get('equipment');
        const slotsUsedByItem = [slot, ...equipable.blocks];
        slotsUsedByItem.forEach((slotId) => {
            equipment.slots[slotId] = itemId;
        });
        // Add item to equipment
        equipment.items.push(itemId);
        this.eventBus.fire('ITEM_EQUIPPED', { entityId, itemId });
    }
    onUnequip({ entityId, itemId, }) {
        // TODO: what if the inventory is full?
        // If this entity does NOT have an equipment component
        if (!this.entityIds.includes(entityId)) {
            return;
        }
        const entity = this.entityManager.getEntity(entityId);
        const equipment = entity.get('equipment');
        if (!equipment.items.includes(itemId)) {
            return;
        }
        // Remove the item from our slots...
        equipment.items = equipment.items.filter((equippedItemId) => equippedItemId !== itemId);
        Object.entries(equipment.slots).forEach(([slot, equippedItemId]) => {
            // If we are still wearing an item that has been unequipped, then remove it
            if (equippedItemId === itemId) {
                delete equipment.slots[slot];
            }
        });
        // Fire the item unequipped event to trigger stat updating...
        this.eventBus.fire('ITEM_UNEQUIPPED', { entityId, itemId });
    }
}
