import statBasic from './stats/statBasic';
const STATS_MAP = [
    {
        name: 'maxLife',
        fn: statBasic,
    },
    {
        name: 'armour',
        fn: statBasic,
    },
    {
        name: 'attackSpeed',
        fn: statBasic,
    },
    {
        name: 'minPhysicalDamage',
        fn: statBasic,
    },
    {
        name: 'maxPhysicalDamage',
        fn: statBasic,
    },
    {
        name: 'movementSpeed',
        fn: statBasic,
    },
];
export default class StatFactory {
    static loadStats(stats) {
        stats.forEach((stat) => {
            this.STATS[stat.id] = stat;
        });
    }
    static getLocalStatModifiers(statId, value) {
        const primaryStat = this.STATS[statId];
        const aliasedStats = [];
        if (!primaryStat) {
            console.error('missing stat', statId, this.STATS);
            return aliasedStats;
        }
        if (!primaryStat.isLocal) {
            return aliasedStats;
        }
        if (!primaryStat.alias.length) {
            return [
                {
                    stat: primaryStat.id,
                    mod: primaryStat.modifier,
                    value,
                },
            ];
        }
        primaryStat.alias.forEach((aliasedStatId) => {
            const aliasedStat = this.STATS[aliasedStatId];
            if (!aliasedStat) {
                return;
            }
            aliasedStats.push({
                stat: aliasedStat.id,
                mod: primaryStat.modifier,
                value,
            });
        });
        return aliasedStats;
    }
    static getGlobalStatModifiers(statId, value) {
        const primaryStat = this.STATS[statId];
        const aliasedStats = [];
        if (!primaryStat) {
            console.error('missing stat', statId);
            return aliasedStats;
        }
        if (primaryStat.isLocal) {
            return aliasedStats;
        }
        if (!primaryStat.alias.length) {
            return [
                {
                    stat: primaryStat.id,
                    mod: primaryStat.modifier,
                    value,
                },
            ];
        }
        primaryStat.alias.forEach((aliasedStatId) => {
            const aliasedStat = this.STATS[aliasedStatId];
            if (!aliasedStat) {
                return;
            }
            aliasedStats.push({
                stat: aliasedStat.id,
                mod: primaryStat.modifier,
                value,
            });
        });
        return aliasedStats;
    }
    static applyModifiers(stats, modifiers) {
        STATS_MAP.forEach((stat) => {
            stat.fn(stats, modifiers, stat.name);
        });
    }
    static getLocalModifier(rolledModifiers) {
        const modifierGroup = {
            add: {},
            increase: {},
            multiply: {},
        };
        rolledModifiers.forEach((rolledModifier) => {
            rolledModifier.stats.forEach((stat) => {
                const modifiers = StatFactory.getLocalStatModifiers(stat.id, stat.value);
                modifiers.forEach((modifier) => {
                    if (!modifierGroup[modifier.mod][modifier.stat]) {
                        modifierGroup[modifier.mod][modifier.stat] = 0;
                    }
                    modifierGroup[modifier.mod][modifier.stat] +=
                        modifier.value;
                });
            });
        });
        return modifierGroup;
    }
    static getGlobalModifier(rolledModifiers) {
        const modifierGroup = {
            add: {},
            increase: {},
            multiply: {},
        };
        rolledModifiers.forEach((rolledModifier) => {
            rolledModifier.stats.forEach((stat) => {
                const modifiers = StatFactory.getGlobalStatModifiers(stat.id, stat.value);
                modifiers.forEach((modifier) => {
                    if (!modifierGroup[modifier.mod][modifier.stat]) {
                        modifierGroup[modifier.mod][modifier.stat] = 0;
                    }
                    modifierGroup[modifier.mod][modifier.stat] +=
                        modifier.value;
                });
            });
        });
        return modifierGroup;
    }
    static convertCurrentStatsToModifier(stats) {
        const modifierGroup = {
            add: {},
            increase: {},
            multiply: {},
        };
        modifierGroup.add = {
            ...stats.current,
        };
        return modifierGroup;
    }
}
StatFactory.STATS = {};
