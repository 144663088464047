import { components, Entity } from '@local/game';
class EntityPool {
    constructor() {
        this.entities = {};
    }
    getEntity(entityId) {
        return this.entities[entityId];
    }
    addEntity(entity) {
        this.entities[entity.id] = entity;
    }
}
export default class CharacterGateway {
    static createCharacter() {
        const characterId = 'playerId-1';
        const startingWeapon = new Entity({
            components: [
                new components.ItemComponent({
                    id: 'iron_sword',
                    itemClass: 'item',
                    name: 'Iron Sword',
                    rarity: 'normal',
                    level: 1,
                    tags: {},
                    modifiers: [],
                }),
                new components.StatsComponent({
                    base: {
                        attackSpeed: 1000,
                        minPhysicalDamage: 2,
                        maxPhysicalDamage: 7,
                    },
                }),
                new components.EquipableComponent({
                    slots: ['leftHand', 'rightHand'],
                    blocks: [],
                }),
            ],
        });
        const debugWeapon = new Entity({
            components: [
                new components.ItemComponent({
                    id: 'iron_sword',
                    itemClass: 'item',
                    name: 'Excalibur',
                    rarity: 'legendary',
                    level: 10,
                    tags: {},
                    modifiers: [],
                }),
                new components.StatsComponent({
                    base: {
                        attackSpeed: 800,
                        minPhysicalDamage: 300,
                        maxPhysicalDamage: 700,
                    },
                }),
                new components.EquipableComponent({
                    slots: ['leftHand', 'rightHand'],
                    blocks: ['leftHand', 'rightHand', 'chest'],
                }),
            ],
        });
        const player = new Entity({
            components: [
                new components.PlayerComponent({ characterId }),
                new components.ProgressComponent({}),
                new components.StatsComponent({
                    base: {
                        life: 55,
                        maxLife: 55,
                        lifeRegen: 0.001,
                        movementSpeed: 3000,
                    },
                }),
                new components.EquipmentComponent({
                    items: [startingWeapon.id],
                    slots: {
                        rightHand: startingWeapon.id,
                    },
                    misc: {
                        lifeCrystals: 4,
                    },
                }),
                new components.InventoryComponent({
                    items: [debugWeapon.id]
                }),
                new components.TargetComponent(),
            ],
        });
        const entities = [startingWeapon, debugWeapon, player];
        const entityManager = new EntityPool();
        entities.forEach((entity) => {
            entityManager.addEntity(entity);
        });
        const state = {};
        entities.forEach((entity) => {
            entity.serialize(entityManager, state);
        });
        this.saveCharacter({ characterId, entityId: player.id, state });
        return [characterId, entities];
    }
    static saveCharacter({ characterId, entityId, state }) {
        if (!window) {
            return;
        }
        const { localStorage } = window;
        if (!localStorage) {
            return;
        }
        const accountStateRaw = localStorage.getItem('account_state');
        let accountState = {};
        if (accountStateRaw) {
            accountState = CharacterGateway.parse(accountStateRaw);
        }
        if (!accountState.characters) {
            accountState.characters = {};
        }
        let previousStorageId;
        if (accountState.characters[characterId]) {
            previousStorageId = accountState.characters[characterId].storageId;
        }
        accountState.characters[characterId] = {
            // characterName: state[entityId].player.characterName
            characterId,
            storageId: `account_state:${characterId}`,
            version: 1,
        };
        try {
            localStorage.setItem('account_state', CharacterGateway.stringify(accountState));
            localStorage.setItem(accountState.characters[characterId].storageId, CharacterGateway.stringify(Object.values(state)));
            if (previousStorageId !==
                accountState.characters[characterId].storageId) {
                localStorage.removeItem(previousStorageId);
            }
            // console.log('#saved');
        }
        catch (e) { }
    }
    static loadCharacter(characterId) {
        if (!window) {
            return [];
        }
        const { localStorage } = window;
        if (!localStorage) {
            return [];
        }
        const accountStateRaw = localStorage.getItem('account_state');
        let accountState = {};
        if (!accountStateRaw) {
            return [];
        }
        accountState = CharacterGateway.parse(accountStateRaw);
        if (!accountState.characters) {
            accountState.characters = {};
        }
        if (!accountState.characters[characterId]) {
            return [];
        }
        if (!accountState.characters[characterId].version) {
            return this.createCharacter()[1] || [];
        }
        const characterStateRaw = localStorage.getItem(accountState.characters[characterId].storageId);
        if (!characterStateRaw) {
            // TODO: ERROR?
            return [];
        }
        console.log('#loaded', characterStateRaw);
        const characterState = CharacterGateway.parse(characterStateRaw);
        console.log('#loaded', characterState);
        return characterState.map((entityData) => Entity.deserialize(entityData));
    }
    static stringify(value) {
        return JSON.stringify(value);
    }
    static parse(value) {
        return JSON.parse(value);
    }
}
