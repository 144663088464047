import * as React from 'react';
const GameClientContext = React.createContext(null);
const GameClientProvider = ({ children, gameClient, }) => (React.createElement(GameClientContext.Provider, { value: gameClient }, children));
function useGameClient() {
    const context = React.useContext(GameClientContext);
    if (context === undefined) {
        throw new Error('useGameClient must be used within a GameClientProvider');
    }
    return context;
}
export { GameClientProvider, useGameClient };
