export default class EngineTimer {
    constructor(duration) {
        this.duration = duration;
        this.elapsed = 0;
    }
    update(gameTime) {
        this.elapsed += gameTime.delta;
        // Latch it to avoid overflow
        if (this.elapsed >= this.duration) {
            this.elapsed = this.duration;
        }
    }
    // What is the usecase?
    updateIncrease(gameTime, increase) {
        this.elapsed += gameTime.delta * increase;
        // Latch it to avoid overflow
        if (this.elapsed >= this.duration) {
            this.elapsed = this.duration;
        }
    }
    reset(duration, elapsed = 0) {
        this.elapsed = elapsed;
        this.duration = duration;
    }
    resetToZero() {
        this.elapsed = 0;
    }
    test() {
        if (this.elapsed >= this.duration) {
            return true;
        }
        return false;
    }
    getDuration() {
        return this.duration;
    }
    getElapsed() {
        return this.elapsed;
    }
}
