import { EngineTimer } from '@local/engine';
import System from '../ecs/system';
export default class CombatSystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.requiredComponentIds = ['stats', 'target'];
        this.attackTimers = {};
    }
    update(gameTime) {
        super.update(gameTime);
        this.entityIds.forEach((entityId) => {
            const entity = this.entityManager.getEntity(entityId);
            const stats = entity.get('stats');
            const entityAttackTimer = this.attackTimers[entityId];
            const expectedAttackDuration = stats.get('attackSpeed') || gameTime.secondsLength;
            // Update attack timer
            if (entityAttackTimer.getDuration() !== expectedAttackDuration) {
                entityAttackTimer.reset(expectedAttackDuration);
            }
            // Find the target
            const target = entity.get('target');
            const targetEntity = this.entityManager.getEntity(target.targetId);
            if (!targetEntity) {
                target.targetId = null;
                entityAttackTimer.resetToZero();
                /*
                TODO: should this remove the component?
                this.eventBus.queue(EVENTS.REMOVE_COMPONENT,{
                    entityId,
                    componentId: target.id,
                }) */
                return;
            }
            entityAttackTimer.update(gameTime);
            if (!entityAttackTimer.test()) {
                return;
            }
            entityAttackTimer.resetToZero();
            const damageRolls = this.calculateDamage(entity.get('stats'), targetEntity.get('stats'));
            this.eventBus.queue('ENTITY_HIT', {
                originId: entity.id,
                targetId: targetEntity.id,
                damageRolls,
            });
        });
    }
    onAddEntity(entity) {
        if (!super.onAddEntity(entity)) {
            return false;
        }
        this.attackTimers[entity.id] = new EngineTimer(0);
        return true;
    }
    onRemoveEntity(entity) {
        if (!super.onRemoveEntity(entity)) {
            return false;
        }
        delete this.attackTimers[entity.id];
        return true;
    }
    calculateDamage(originStats, targetStats) {
        const damageRolls = {
            totalDamage: 0,
            physicalDamage: 0,
        };
        const minPhysicalDamage = originStats.get('minPhysicalDamage');
        const maxPhysicalDamage = originStats.get('maxPhysicalDamage');
        damageRolls.physicalDamage = this.random.range(minPhysicalDamage, maxPhysicalDamage);
        // Calculate damage reduction...
        const targetArmour = targetStats.get('armour');
        // subtract the targets armour from the physical damage
        // Armour reduce physical damage by 10%;
        damageRolls.physicalDamage -= targetArmour * 0.1;
        damageRolls.physicalDamage = Math.max(damageRolls.physicalDamage, 0);
        damageRolls.totalDamage += damageRolls.physicalDamage;
        return damageRolls;
    }
}
