import { ChevronLeftIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import EquipmentSlots from './equipmentSlots';
import InventorySlots from './inventorySlots';
import Panel from './panel';
const InventoryPanel = () => {
    // TODO: listen to global state
    const [isOpen, setOpen] = useState(false);
    const toggleInventory = () => setOpen(!isOpen);
    const closeInventory = () => setOpen(false);
    useHotkeys('i', () => setOpen((prevState) => !prevState), { filterPreventDefault: true }, [setOpen]);
    return (React.createElement(Panel, { isOpen: isOpen, className: "inset-y-0 right-0 w-2/3 sm:w-1/2 lg:w-1/3 border-l-2 border-b-2 border-gray-800 dark:border-gray-400 pt-12 rounded-bl-md", isOpenClass: "translate-x-full" },
        React.createElement("div", { className: "absolute top-0 left-0 w-full h-12 flex flex-row bg-gray-800 border-b-2 border-gray-800 dark:border-gray-400" },
            React.createElement("div", { className: "w-4/12" }),
            React.createElement("div", { className: "flex justify-center items-center w-4/12 text-white text-lg font-semibold" }, "Inventory"),
            React.createElement("div", { className: "flex justify-end w-4/12" },
                React.createElement("button", { type: "button", className: "h-12 w-12 flex justify-center items-center outline-none focus:outline-none", onClick: closeInventory },
                    React.createElement(XIcon, { className: "h-8 w-8 text-white transform" })))),
        React.createElement("button", { type: "button", className: "absolute top-0 left-0 transform -translate-x-full h-12 w-12 flex justify-center items-center outline-none focus:outline-none border-2 border-t-0 border-r-0 border-gray-800 dark:border-gray-400 bg-gray-800 rounded-bl-xl", onClick: toggleInventory },
            React.createElement(ChevronLeftIcon, { className: clsx('h-8 w-8 text-white transform', {
                    'rotate-180': isOpen,
                }) })),
        React.createElement("div", { className: clsx('relative h-full w-full bg-gray-600 shadow-2xl flex flex-col overflow-y-scroll ') }, isOpen && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "border-b-2 border-gray-800 dark:border-gray-400 p-4 pl-2 pr-2 bg-equipment" },
                React.createElement(EquipmentSlots, null)),
            React.createElement("div", { className: "bg-gray-700 flex-grow" },
                React.createElement(InventorySlots, null)))))));
};
export default InventoryPanel;
