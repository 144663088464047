import React from 'react';
import ZoneEvent from './zoneEvent';
import ZoneGround from './zoneGround';
import ZoneProgress from './zoneProgress';
const Zone = () => (React.createElement("div", { className: "flex flex-col w-full space-y-6 p-6" },
    React.createElement("div", { className: "flex w-full" },
        React.createElement("div", { className: "w-full" },
            React.createElement(ZoneProgress, null))),
    React.createElement("div", { className: "flex flex-col sm:flex-row w-full space-y-6 sm:space-y-0 justify-between" },
        React.createElement("div", { className: "w-full sm:w-1/2" },
            React.createElement(ZoneEvent, null))),
    React.createElement("div", { className: "flex flex-col sm:flex-row w-full sm:space-y-0 justify-between" },
        React.createElement("div", { className: "w-full sm:w-1/2 order-1 mb-6 sm:mb-0 sm:order-2" },
            React.createElement(ZoneGround, null)))));
export default Zone;
