import System from '../ecs/system';
// TODO: add joi validation schemas...
const INPUT_TO_SYSTEM_EVENTS = {
    PICKUP_ITEM: 'PICKUP_ITEM',
    DROP_ITEM: 'DROP_ITEM',
    EQUIP_ITEM: 'TRY_EQUIP_ITEM',
    USE_LIFE_CRYSTAL: 'USE_LIFE_CRYSTAL',
    LOAD_ZONE: 'LOAD_ZONE',
    LEAVE_ZONE: 'LEAVE_ZONE',
    CONTINUE_ZONE: 'CONTINUE_ZONE',
    RESPAWN: 'PLAYER_RESPAWN',
};
export default class PlayerInputSystem extends System {
    constructor(eventBus, entityManager, random) {
        super(eventBus, entityManager, random);
        this.requiredComponentIds = ['player'];
        this.onPlayerInput = this.onPlayerInput.bind(this);
        this.eventBus.addListener('PLAYER_INPUT', this.onPlayerInput);
    }
    onPlayerInput({ entityId, eventId, eventValue, }) {
        const targetEventId = INPUT_TO_SYSTEM_EVENTS[eventId];
        if (!targetEventId) {
            return;
        }
        // IF this entity is not a player
        if (!this.entityIds.includes(entityId)) {
            return;
        }
        console.log('#player_input', entityId, eventId, '->', targetEventId, '=', eventValue);
        // The entityId on player events is always the player
        // TODO: validate input arguments against events...
        const scopedEventValue = {
            ...eventValue,
            entityId,
        };
        // should this queue the event?
        this.eventBus.fire(targetEventId, scopedEventValue);
    }
}
