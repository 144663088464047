import seedrandom from 'seedrandom';
export default class Random {
    constructor(seed) {
        // @ts-ignore
        this.rng = seedrandom(seed);
    }
    new(seed) {
        // @ts-ignore
        this.rng = seedrandom(seed);
    }
    rollRange(range) {
        let roll;
        const [lower, upper] = range;
        if (upper === undefined) {
            roll = lower;
        }
        else {
            roll = this.range(lower, upper);
        }
        return roll;
    }
    range(min, max) {
        if (max === undefined) {
            return min;
        }
        return Math.floor(this.rng() * (max - min + 1) + min);
    }
    next() {
        return this.rng();
    }
}
